import React from 'react';
import {Image, Typography, Button} from 'components/common';
import * as S from './styled';
import {isEmpty} from 'lodash';
import {useSelector} from "react-redux";

export const PartnerDesktop = () => {
    const {
        selected: {payload: lp}
    } = useSelector(({landingPage}) => landingPage);
    return (
        <>
            {!isEmpty(lp) &&
            <S.Container
                id="d_partners"
                bgColor={lp.sobreParceria.corSecundaria}
            >
                <S.PartnerContainer>
                    <S.PartnerImage>
                        <Image alt='Main Photo' src={lp.arquivo.linkImagemSobreParceria}/>
                    </S.PartnerImage>

                    <S.PartnerInfo>
                        <S.PartnerTitle>
                            <h1 style={{color: lp.sobreParceria.corPrimaria}}>
                                {lp.sobreParceria.tituloSobreParceria}
                            </h1>
                        </S.PartnerTitle>
                        <S.PartnerSubtitle>
                            <Typography tag="text">
                                {lp.sobreParceria.textoSobreParceria}
                            </Typography>
                        </S.PartnerSubtitle>
                        <S.PartnerButton>
                            <Button
                                style={{backgroundColor: lp.sobreParceria.corPrimaria}}
                                href="#d_offers"
                            >Peça o seu!</Button>
                        </S.PartnerButton>
                    </S.PartnerInfo>
                </S.PartnerContainer>
            </S.Container>
            }
        </>
    )
}
