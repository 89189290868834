import React from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Image,
  Typography,
  Row,
  Col,
  Grid,
  PurchaseInfo,
} from "components/common";
import ArrowLeftPrimary from "assets/icons/arrow-left-primary.svg";
import CompleteIcon from "assets/icons/complete.svg";
import { PersonalData } from "../PersonalData";
import { Address } from "../Address";
import { Payment } from "../Payment";
import { Confirmation } from "../Confirmation";
import * as S from "./styled";


export const PaymentFlowPage = () => {
  const history = useHistory();
  const { step } = useParams();

  const stagePersonalData = (menu) => {
    switch (menu) {
      case "personal-data":
        return "selected";
      default:
        return "default";
    }
  };

  const stageAddress = (menu) => {
    switch (menu) {
      case "personal-data":
        return "complete";
      case "address":
        return "selected";
      default:
        return "default";
    }
  };

  const stagePayment = (menu) => {
    switch (menu) {
      case "personal-data":
        return "complete";
      case "address":
        return "complete";
      case "payment":
        return "selected";
      default:
        return "default";
    }
  };

  const stageConfirmation = (menu) => {
    switch (menu) {
      case "personal-data":
        return "complete";
      case "address":
        return "complete";
      case "payment":
        return "complete";
      case "confirmation":
        return "complete";
      default:
        return "default";
    }
  };

  const variant = (menu) => {
    switch (step) {
      case "personal-data":
        return stagePersonalData(menu);
      case "address":
        return stageAddress(menu);
      case "payment":
        return stagePayment(menu);
      case "confirmation":
        return stageConfirmation(menu);
      default:
        return "default";
    }
  };

  const stepIconPersonalData = () => {
    switch (step) {
      case "personal-data":
        return "1.";
      default:
        return <Image src={CompleteIcon} alt="ok" />;
    }
  };

  const stepIconAddress = () => {
    switch (step) {
      case "personal-data":
      case "address":
        return "2.";
      default:
        return <Image src={CompleteIcon} alt="ok" />;
    }
  };

  const stepIconPayment = () => {
    switch (step) {
      case "personal-data":
      case "address":
      case "payment":
        return "3.";
      default:
        return <Image src={CompleteIcon} alt="ok" />;
    }
  };

  const stepIconConfirmation = () => {
    switch (step) {
      case "personal-data":
      case "address":
      case "payment":
        return "4.";
      default:
        return <Image src={CompleteIcon} alt="ok" />;
    }
  };

  const renderForm = () => {
    switch (step) {
      case "personal-data":
        return <PersonalData />;
      case "address":
        return (
          <Address
            onBack={() => history.push("/payment-flow/personal-data")}
            onNext={() => history.push("/payment-flow/payment")}
          />
        );
      case "payment":
        return <Payment
                  onBack={() => history.push("/payment-flow/address")}
                  onNext={() => history.push("/payment-flow/confirmation")} />;
      case "confirmation":
        return <Confirmation />;
      default:
        break;
    }
  };

  const pageTitle = {
    "personal-data": "Dados pessoais",
    address: "Endereço de entrega",
    payment: "Pagamento",
  };

  const subtitle = {
    "personal-data": "* Campo de preenchimento obrigatório",
    address: "* Campo de preenchimento obrigatório",
    payment:
      "Escolha um método de pagamento para realizar sua aquisição de adesivo.",
  };

  const btnBack = {
    "personal-data": () => history.push("/"),
    address: () => history.push("/payment-flow/personal-data"),
    payment: () => history.push("/payment-flow/address"),
  };
  return (
    <S.Container>
      <S.StepWrapper>
        <S.ItemStep variant={variant("personal-data")}>
          {stepIconPersonalData()} <span>Dados Pessoais</span>
        </S.ItemStep>
        <S.Separator />
        <S.ItemStep variant={variant("address")}>
          {stepIconAddress()} <span>Endereço</span>
        </S.ItemStep>
        <S.Separator />
        <S.ItemStep variant={variant("payment")}>
          {stepIconPayment()} <span>Pagamento</span>
        </S.ItemStep>
        <S.Separator />
        <S.ItemStep variant={variant("confirmation")}>
          {stepIconConfirmation()} <span>Confirmação</span>
        </S.ItemStep>
      </S.StepWrapper>
      <S.FormWrapper>        
          <Col xs={12} lg={8}>
            <S.ContainerForm step={step}>
              {step !== "confirmation" && (
                <Image
                  className="icon-back"
                  onClick={btnBack[step]}
                  src={ArrowLeftPrimary}
                  alt="back"
                  style={{ marginBottom: 14, cursor: "pointer" }}
                />
              )}
              <Typography tag="h5" spacing="xs">
                {pageTitle[step]}
              </Typography>
              <Typography tag="disclaimer" spacing="mdx">
                {subtitle[step]}
              </Typography>
              <S.PaymentForm>
                {renderForm()}
              </S.PaymentForm>
            </S.ContainerForm>
          </Col>
          {step !== "confirmation" && (
            <Col xs={12} lg={4}>
              <S.ContainerPurchaseInfo>
                <PurchaseInfo onOpen={() => { }} />
              </S.ContainerPurchaseInfo>
            </Col>
          )}        
      </S.FormWrapper>
    </S.Container>
  );
};
