import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { Image, Typography } from "components/common";
import CloseCircle from "assets/icons/close-circle.svg";
import QuestionMark from "assets/icons/question-mark.svg";
import { actionSetRegistration } from "redux/actions/Registration/Data";

import * as S from "./styled";

export const PurchaseInfo = ({ onOpen }) => {
  const [planSelected, setPlanSelected] = useState({});
  const dispatch = useDispatch();

  const {
    selected: { payload: lp },
  } = useSelector(({ landingPage }) => landingPage);

  const {
    data: { payload },
  } = useSelector(({ registration }) => registration);

  useEffect(() => {
    if (!isEmpty(lp)) {
      const newPlanSelected = lp.ofertas[0].planos[payload.indexSelectedOrder];
      setPlanSelected(newPlanSelected);
      dispatch(
        actionSetRegistration({
          ...payload,
          planSelected: newPlanSelected,
        })
      );
    }
  }, [lp]);

  return (
    <S.PurchaseInfoFullHeight>
      <S.PurchaseInfoHeader>
        <div>
          <Typography tag="h2" variant="secondary" spacing="xs">
            Sua Compra
          </Typography>
          <Typography tag="h1">{planSelected.tituloPlano}</Typography>
        </div>
        <S.BtnChangeModal onClick={onOpen}>
          <Image src={CloseCircle} alt="close" />
        </S.BtnChangeModal>
      </S.PurchaseInfoHeader>
      <S.PurchaseContent>
        <S.PlanBoxSimple>
          <Typography tag="h5" variant="secondary">
            Sem mensalidade fixa
          </Typography>
          <Typography tag="text" variant="secondary">
            Pague por recarga
          </Typography>
        </S.PlanBoxSimple>
      </S.PurchaseContent>
      <S.RowInfo>
        <div>
          <Typography tag="default">Mensalidade</Typography>
          <Typography tag="default">
            <strong>Sem mensalidade fixa</strong>
          </Typography>
        </div>
        <Typography tag="default">R$ 0,00</Typography>
      </S.RowInfo>
      <S.RowInfo>
        <Typography tag="default">Frete</Typography>
        <Typography tag="default">Grátis</Typography>
      </S.RowInfo>
      <S.RowInfo>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography tag="default">Primeira recarga</Typography>
        </div>

        <Typography tag="default">1x {planSelected.valorPlano}</Typography>
        <S.Badge
          fullWidth
          size="sm"
          variant="primary"
          style={{ marginTop: 16 }}
        >
          Taxa de adesão de {planSelected.valorAdesaoFormatado} por adesivo.
        </S.Badge>
      </S.RowInfo>
      <S.RowInfo borderNone>
        <Typography tag="default">Total</Typography>
        <Typography tag="h1">{planSelected.valorAdesaoFormatado}</Typography>
      </S.RowInfo>
    </S.PurchaseInfoFullHeight>
  );
};

PurchaseInfo.propTypes = {
  onOpen: PropTypes.func.isRequired,
};
