import {
  GET_WIDGET_TOKEN_FAILURE,
  GET_WIDGET_TOKEN_SUCCESS,
  GET_WIDGET_TOKEN_REQUEST,
  CREATE_PAYMENT_PP_REQUEST,
  CREATE_PAYMENT_PP_SUCCESS,
  CREATE_PAYMENT_PP_FAILURE,
  CREATE_PAYMENT_LP_REQUEST,
  CREATE_PAYMENT_LP_SUCCESS,
  CREATE_PAYMENT_LP_FAILURE,
} from "redux/constants/Payment/Data";

import { getWidgetToken, postOrderPP, postOrderLP } from "config/api/Payment";

// GET
const fetchGetWidgetTokenRequestRequest = () => ({
  type: GET_WIDGET_TOKEN_REQUEST,
});

const fetchGetWidgetTokenSuccess = (payload) => ({
  type: GET_WIDGET_TOKEN_SUCCESS,
  payload,
});

const fetchGetWidgetTokenFailure = (payload) => ({
  type: GET_WIDGET_TOKEN_FAILURE,
  payload,
});

const fetch = (dispatch) => {
  dispatch(fetchGetWidgetTokenRequestRequest());
  getWidgetToken()
    .then((response) => {
      dispatch(fetchGetWidgetTokenSuccess(response.data));
    })
    .catch(() => {
      dispatch(fetchGetWidgetTokenFailure());
    });
};

export const actionGetWidgetToken = () => (dispatch) => fetch(dispatch);

// POST PP

const createPaymentPPRequestRequest = () => ({
  type: CREATE_PAYMENT_PP_REQUEST  
});

const createPaymentPPSuccess = (payload) => ({
  type: CREATE_PAYMENT_PP_SUCCESS,
  payload,
});

const createPaymentPPFailure = (payload) => ({
  type: CREATE_PAYMENT_PP_FAILURE,
  payload,
});

const createPP = (request, dispatch, nextAction) => {  
  dispatch(createPaymentPPRequestRequest());
  postOrderPP(request)
    .then((response) => {
      dispatch(createPaymentPPSuccess(response.data));
      nextAction();
    })
    .catch(() => {
      dispatch(createPaymentPPFailure());
    });
};

export const actionCreatePP = (request, dispatch, nextAction) => {  
  createPP(request, dispatch, nextAction);
}

// POST LP

const createPaymentLPRequestRequest = () => ({
  type: CREATE_PAYMENT_LP_REQUEST,
});

const createPaymentLPSuccess = (payload) => ({
  type: CREATE_PAYMENT_LP_SUCCESS,
  payload,
});

const createPaymentLPFailure = (payload) => ({
  type: CREATE_PAYMENT_LP_FAILURE,
  payload,
});

const createLP = (request, dispatch, nextAction) => {
  dispatch(createPaymentLPRequestRequest());
  postOrderLP(request)
    .then((response) => {        
      dispatch(createPaymentLPSuccess(response.data));
      nextAction();        
    })
    .catch(() => {
      dispatch(createPaymentLPFailure());
    });
};

export const actionCreateLP = (request, dispatch, nextAction) => {
  createLP(request, dispatch, nextAction);
}
