import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Image, Typography } from "components/common";
import HeaderLogo from "assets/images/conectcar-logo.svg";
import ArrowLeftPrimary from "assets/icons/arrow-left-primary.svg";
import { useSelector } from "react-redux";
import { isEmpty } from 'lodash';

import * as S from "./styled";

const Header = () => {
  const { step } = useParams();
  const history = useHistory();

  const [planSelected, setPlanSelected] = useState({});

  const {
    data: { payload: orderData },
  } = useSelector(({ order }) => order);

  const {
    selected: { payload: lp },
  } = useSelector(({ landingPage }) => landingPage);

  const {
    data: { payload },
  } = useSelector(({ registration }) => registration);

  useEffect(() => {
    if (!isEmpty(lp)) {
      const newPlanSelected = lp.ofertas[0].planos[payload.indexSelectedOrder];
      setPlanSelected(newPlanSelected);
    }
  }, [lp])


  const renderTotalStepNumber = () => <S.StepNumber isLast>04</S.StepNumber>;

  const renderStepNumber = () => {
    switch (step) {
      case "personal-data":
        return <S.StepNumber isLast={false}>01</S.StepNumber>;
      case "address":
        return <S.StepNumber isLast={false}>02</S.StepNumber>;
      case "payment":
        return <S.StepNumber isLast={false}>03</S.StepNumber>;
      case "confirmation":
        return <S.StepNumber isLast> 04</S.StepNumber>;
      default:
        return <S.StepNumber isLast={false}>00</S.StepNumber>;
    }
  };

  const renderStepName = () => {
    switch (step) {
      case "personal-data":
        return <S.StepNumber isLast={false}>{`Passo `}</S.StepNumber>;
      case "address":
        return <S.StepNumber isLast={false}>{`Passo `}</S.StepNumber>;
      case "payment":
        return <S.StepNumber isLast={false}>{`Passo `}</S.StepNumber>;
      case "confirmation":
        return <S.StepNumber isLast> {`Passo `}</S.StepNumber>;
      default:
        return <S.StepNumber isLast={false}>{`Passo `}</S.StepNumber>;
    }
  };

  const renderTitle = () => {
    switch (step) {
      case "personal-data":
        return "Dados Pessoais";
      case "address":
        return "Endereço";
      case "payment":
        return "Pagamento";
      case "confirmation":
        return "Confirmação";
      default:
        return "-";
    }
  };

  return (
    <S.Container>
      <Image src={HeaderLogo} alt="header-logo" width="111px" height="25px" />
      <S.Step>
        <Typography tag="subtitle" spacing="xs">
          {renderStepName()}
          {renderStepNumber()}
          {` / `}
          {renderTotalStepNumber()}
        </Typography>
        <Typography tag="h5">{renderTitle()}</Typography>
      </S.Step>
      <S.HeaderInfo>
        <S.TextBox>
          <S.Separator />
          <div>
            <Typography tag="h2" variant="secondary" spacing="min">
              Bem vindo ao ambiente de compra da ConectCar
            </Typography>
            <Typography tag="text">
              Você está comprando adesivo para o{" "}
              <strong style={{ color: "#FF6338" }}>plano {planSelected.tituloPlano?.toLowerCase()}</strong>, em um
              ambiente totalmente seguro.
            </Typography>
          </div>
        </S.TextBox>
        <S.BtnBackToHome onClick={() => history.push(`/?lp=${lp.dns}#d_offers`)}>
          <Image
            src={ArrowLeftPrimary}
            alt="back"
            style={{ marginRight: 10 }}
          />
          Voltar para a Home
        </S.BtnBackToHome>
      </S.HeaderInfo>
    </S.Container>
  );
};

export default Header;
