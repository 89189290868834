import DoneCircle from "assets/icons/done-circle.svg";
import ShareIcon from "assets/icons/share.svg";
import HeaderLogo from "assets/images/conectcar-logo.svg";
import { Button, Col, Image, Row, Typography } from "components/common";
import React from "react";
import { useSelector } from "react-redux";
import { createAndDownloadBlobFile } from "utils/file/donwload-file";
import * as S from "./styled";

export const CompanyConfirmation = () => {    

  const {
    data: { payload: orderData },
  } = useSelector(({ order }) => order);   

  return (
    <S.Container>
      <S.Card>
        <S.CardRow>
          <Image src={HeaderLogo} alt="header-logo" />
          {/* <Image src={ShareIcon} alt="share" className="btn-share" /> */}
        </S.CardRow>
        <S.CardContent>
          <Image src={DoneCircle} alt="done-circle" spacing="smx" />
          <Typography tag="h3" variant="primary" spacing="smx" align="center">
            Seu pedido foi recebido e será processado após o pagamento do boleto
          </Typography>
          <Typography tag="support" variant="primary" spacing="smx" align="center">
            Você receberá um e-mail de confirmação com os detalhes do seu pedido
          </Typography>         
        </S.CardContent>

        <S.CardContent>
          <S.CardRow>
            <Typography tag="default" variant="secondary" spacing="sml">
              {orderData.numeroPedido}
            </Typography>
          </S.CardRow>
          <S.CardRow>
            <Typography tag="h4" variant="secondary" spacing="sml">
              Forma de pagamento:
            </Typography>
            <Typography tag="default" variant="secondary" spacing="sml">
              Boleto
            </Typography>
          </S.CardRow>
          <S.CardRow>
            <Typography tag="h4" variant="secondary" spacing="sml">
              Status:
            </Typography>
            <Typography
              tag="default"
              variant="secondary"
              spacing="sml"
              style={{ color: "#FF6338" }}
            >
              Aguardando
            </Typography>
          </S.CardRow>
        </S.CardContent>
        <S.Action>
          <Row gutterWidth={40} align="center">
            <Col xs={12} sm={6}>
              <Button id="btn_save_account" type="button" onClick={() => createAndDownloadBlobFile(orderData.hashBytesBoleto, 'boleto')}>
                Baixar Boleto
              </Button>
            </Col>
            <Col xs={12} sm={6}>
              <Button id="btn_save_account" variant="secondary" type="a" onClick={() => window.open(orderData.urlBoleto, '_blank')}>
                Ver Boleto
              </Button>
            </Col>
          </Row>
        </S.Action>
      </S.Card>
      <S.CardFooter>
        ConectCar Soluções de Mobilidade Eletrônica S/A CNPJ
        <br />
        CNPJ: 16.577.631/0002-99 <br />
        Av. Presidente Juscelino Kubitschek, nº 1830 - 6º andar - Torre III
        <br />
        Vila Nova Conceição - São Paulo-SP - CEP 04543-000
      </S.CardFooter>
    </S.Container>
  );
};
