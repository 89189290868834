import styled from 'styled-components'
import breakpoints from "../../../../utils/mediaQueries/media";

export const Container = styled.section`
  background: ${(props) => props.bgColor};
  display: flex;
  padding-bottom: 40px;

  @media (max-width: ${breakpoints.lg}) {
    display: none;
  }
`

export const PartnerContainer = styled.div`
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      width: 1300px;
`


export const PartnerTitle = styled.div`
   color: #004077;
   font-size: 35px;
   font-weight: 700;
   margin: 8px 0;
`

export const PartnerSubtitle = styled.div`
    color: #4D5761;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 32px;
`

export const PartnerImage = styled.div`
    margin-top: 40px;
    width: 90%;
    
    & img {
        border-radius: 8px;
        width: fit-content;
        height: 500px;
    }
`

export const PartnerButton = styled.div`
    margin-top: 18px;
    width: 100%;
    
    & button {
       background: #004077;
    }
`

export const PartnerInfo = styled.div`
  align-self: center;
  margin-left: 40px;
  width: 100%;
`

