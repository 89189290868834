import { SHOW_SNACKBAR } from "redux/constants/Dialogs/Snackbar";
import { SHOW_SPINNER } from "redux/constants/Wrapper/Spinner";
import {
  GET_LANDING_PAGE_BY_ID_FAILURE,
  GET_LANDING_PAGE_BY_ID_SUCCESS,
  GET_LANDING_PAGE_BY_ID_REQUEST,
} from "redux/constants/LandingPage/Data";
import {
  GET_WIDGET_TOKEN_FAILURE,
  GET_WIDGET_TOKEN_SUCCESS,
  GET_WIDGET_TOKEN_REQUEST,
  CREATE_PAYMENT_PP_REQUEST,
  CREATE_PAYMENT_PP_SUCCESS,
  CREATE_PAYMENT_PP_FAILURE,
  CREATE_PAYMENT_LP_REQUEST,
  CREATE_PAYMENT_LP_SUCCESS,
  CREATE_PAYMENT_LP_FAILURE,
} from "redux/constants/Payment/Data";

import {
  FETCH_GET_ADDRESS_BY_POSTAL_CODE_FAILURE,
  FETCH_GET_ADDRESS_BY_POSTAL_CODE_REQUEST,
  FETCH_GET_ADDRESS_BY_POSTAL_CODE_SUCCESS,
} from "redux/constants/Address/Data";

const payloadError = {
  show: true,
  type: "error",
  message: "Erro ao realizar ação. Tente novamente mais tarde!",
};

export const spinnerMiddleware = (store) => (next) => (action) => {  
  if (action !== undefined) {
    switch (action.type) {
      case GET_LANDING_PAGE_BY_ID_REQUEST:
      case GET_WIDGET_TOKEN_REQUEST:
      case FETCH_GET_ADDRESS_BY_POSTAL_CODE_REQUEST:
      case CREATE_PAYMENT_PP_REQUEST:
      case CREATE_PAYMENT_LP_REQUEST:
        store.dispatch({
          type: SHOW_SPINNER,
          payload: true,
        });
        break;
      case GET_LANDING_PAGE_BY_ID_FAILURE:
      case GET_LANDING_PAGE_BY_ID_SUCCESS:
      case GET_WIDGET_TOKEN_FAILURE:
      case GET_WIDGET_TOKEN_SUCCESS:
      case FETCH_GET_ADDRESS_BY_POSTAL_CODE_FAILURE:
      case FETCH_GET_ADDRESS_BY_POSTAL_CODE_SUCCESS:
      case CREATE_PAYMENT_PP_SUCCESS:
      case CREATE_PAYMENT_PP_FAILURE:
      case CREATE_PAYMENT_LP_SUCCESS:
      case CREATE_PAYMENT_LP_FAILURE:
        store.dispatch({
          type: SHOW_SPINNER,
          payload: false,
        });
        break;
      default:
        break;
    }
    next(action);
  }
};

export const snackbarErrorMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case GET_LANDING_PAGE_BY_ID_FAILURE:
    case GET_WIDGET_TOKEN_FAILURE:
    case FETCH_GET_ADDRESS_BY_POSTAL_CODE_FAILURE:
    case CREATE_PAYMENT_PP_FAILURE:
    case CREATE_PAYMENT_LP_FAILURE:
      store.dispatch({
        type: SHOW_SNACKBAR,
        payload: payloadError,
      });
      break;
    case GET_LANDING_PAGE_BY_ID_SUCCESS:
    case GET_LANDING_PAGE_BY_ID_REQUEST:
    case GET_WIDGET_TOKEN_REQUEST:
    case GET_WIDGET_TOKEN_SUCCESS:
    case FETCH_GET_ADDRESS_BY_POSTAL_CODE_REQUEST:
    case FETCH_GET_ADDRESS_BY_POSTAL_CODE_SUCCESS:
      store.dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          show: false,
          errorDetails: null,
        },
      });
      break;
    default:
      break;
  }
  next(action);
};

export const snackbarHandleExceptionMiddleware =
  (store) => (next) => (action) => {
    switch (action.type) {
      case "POST_VALIDATE_TOKEN_FAILURE":
        store.dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            show: true,
            type: "error",
            message: action.payload,
          },
        });
        break;
      case "POST_VALIDATE_TOKEN_REQUEST":
        store.dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            show: false,
            errorDetails: null,
          },
        });
        break;
      default:
        break;
    }
    next(action);
  };

export const snackbarSuccessMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case CREATE_PAYMENT_PP_SUCCESS:
    case CREATE_PAYMENT_LP_SUCCESS:
      store.dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          show: true,
          type: "",
          message: "Inclusão realizada com sucesso!",
        },
      });
      break;
    case "UPDATE_USER_SUCCESS":
      store.dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          show: true,
          type: "",
          message: "Atualização realizada com sucesso!",
        },
      });
      break;
    default:
      break;
  }
  next(action);
};
