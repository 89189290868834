import React from 'react';
import {isEmpty} from 'lodash';
import {Image, Typography, Row, Col} from 'components/common';
import ParaVc from 'assets/icons/main-paravc.svg';
import ParaEmp from 'assets/icons/main-paraempresa.svg';
import Adeviso from 'assets/icons/main-adeviso.svg';
import * as S from './styled';
import {useSelector} from "react-redux";

export const MainPanelMobile = ({onClickOfferType}) => {
    const {
        selected: {payload: lp}
    } = useSelector(({landingPage}) => landingPage);

    return (
        <>
            {!isEmpty(lp) &&
            <S.MobileContainer
                id="homepage"
                primaryColor={lp.painelPrincipal.corPrimaria}
                secondaryColor={lp.painelPrincipal.corSecundaria}
            >
                <S.MobileMainTags
                    bgColor={lp.painelPrincipal.corSecundaria}
                >
                    <Typography tag="text"  style={{color: "white"}}>
                        {lp.painelPrincipal.infoTag}
                    </Typography>
                </S.MobileMainTags>
                <S.MobileMainTitle>
                    <h1>
                        {lp.painelPrincipal.tituloPrincipal}
                    </h1>
                </S.MobileMainTitle>
                <S.MobileMainSubtitle>
                    {lp.painelPrincipal.textoPrincipal}
                </S.MobileMainSubtitle>
                <S.MobileCTAContainer>
                    <Row>
                        <Col xs={12}>
                            <S.MobileMainIcon marginLeft='1' marginTop='0' href="#offers" onClick={() => onClickOfferType('foryou')}>
                                <Image alt="Para voce" src={ParaVc}/>
                                <Typography tag="text" variant="secondary" align="center">
                                    Para você
                                </Typography>
                            </S.MobileMainIcon>
                        </Col>
                        <Col xs={12}>
                            <S.MobileMainIcon marginLeft='2.5' marginTop='0' href="#offers" onClick={() => onClickOfferType('company')}>
                                <Image alt="Para voce" src={ParaEmp}/>
                                <Typography tag="text" variant="secondary" align="center">
                                    Para sua empresa
                                </Typography>
                            </S.MobileMainIcon>
                        </Col>
                    </Row>
                </S.MobileCTAContainer>
                <S.MobileMainImage>
                    <Image alt='Main Photo' src={lp.arquivo.linkImagemPrincipal}/>
                </S.MobileMainImage>
            </S.MobileContainer>
            }
        </>
    )
}
