import styled from 'styled-components'
import breakpoints from "../../../../utils/mediaQueries/media";

export const HeaderBase = styled.div`
    background: ${(props) => props.primaryColor};
    display: flex;
    height: max-content;
    
    @media(max-width: ${breakpoints.lg}) {
        display: none;
    }
`

export const Header = styled.header`
  align-items:center;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  width: 1380px;

`
export const HeaderMask = styled.div`
    background: #F2F2F2;
    width: 100%;
    position:absolute;
    
`


export const HeaderConnect = styled.div`
  margin-right: 17px;
`

export const HeaderPartner = styled.div`
  padding-left: 19px;
  border-left: 1px solid ${({ theme }) => theme.colors.tertiary.light};
`

export const HeaderMenu = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 100%;
  & h2 {
    margin-right: 46px;
  }
  
  & a {
    color: #ffffff;
    text-decoration:none;
  }
`

