import React from "react";
import { Route } from "react-router-dom";
import { ListPage, PaymentFlowPage, CompanyPaymentFlowPage } from "pages";
import {
  PrivateTemplate,
  RegisterTemplate,
  CompanyRegisterTemplate,
} from "components/templates";

const Routes = () => {
  return (
    <>
      <Route path="/payment-flow/:step">
        <RegisterTemplate>
          <PaymentFlowPage />
        </RegisterTemplate>
      </Route>
      <Route path="/company-payment-flow/:step">
        <CompanyRegisterTemplate>
          <CompanyPaymentFlowPage />
        </CompanyRegisterTemplate>
      </Route>
      <Route exact path="/">
        <PrivateTemplate>
          <ListPage />
        </PrivateTemplate>
      </Route>
    </>
  );
};

export default Routes;
