import {
  GET_LANDING_PAGE_BY_ID_FAILURE,
  GET_LANDING_PAGE_BY_ID_SUCCESS,
  GET_LANDING_PAGE_BY_ID_REQUEST
} from 'redux/constants/LandingPage/Data'

const initialState = {
  loading: false,
  error: false,
  payload: {}
}

const Data = (state = initialState, action) => {
  switch (action.type) {
    case GET_LANDING_PAGE_BY_ID_REQUEST:
      return {
        payload: {},
        loading: true,
        error: false
      }
    case GET_LANDING_PAGE_BY_ID_SUCCESS:
      return {
        payload: action.payload.dados,
        loading: false,
        error: false
      }
    case GET_LANDING_PAGE_BY_ID_FAILURE:
      return {
        payload: {},
        loading: false,
        error: true
      }
    default:
      return state
  }
}

export default Data
