import React, {useState} from 'react'
import {Image, Typography} from 'components/common'
import WhiteLogo from 'assets/images/white-logo.svg'
import PartnerLogo from 'assets/images/partner-logo.svg'
import MobileMenu from 'assets/icons/menu-mobile.svg'
import CloseMenu from 'assets/icons/close-menu.svg'
import * as S from './styled'
import {isEmpty} from 'lodash';
import {useSelector} from "react-redux";

export const HeaderMobile = () => {
    const [open, setOpen] = useState(false)
    const {
        selected: {payload: lp}
    } = useSelector(({landingPage}) => landingPage);
    return (
        <>
            {!isEmpty(lp) &&
            <S.Header
                primaryColor={lp.painelPrincipal.corPrimaria}
                secondaryColor={lp.painelPrincipal.corSecundaria}
            >
                <S.HeaderConnect>
                    <Image src={WhiteLogo} alt="white-logo"/>
                </S.HeaderConnect>
                <S.HeaderPartner>
                    <Image src={PartnerLogo} alt="partner-logo"/>
                </S.HeaderPartner>
                <S.HeaderMenu onClick={() => setOpen(true)}>
                    <Image src={MobileMenu} alt="mobile-menu" style={{marginLeft: 8}}/>
                </S.HeaderMenu>
            </S.Header>
            }
            { open&&(
                <S.HeaderMenuItems>
                    <Image src={CloseMenu} alt="white-logo" onClick={() => setOpen(false)}/>
                    <Typography tag="h2" align="center" style={{color: 'white'}}><a href="#homepage">Início</a></Typography>
                    <Typography tag="h2" align="center" style={{color: 'white'}}><a href="#benefits">Benefícios</a></Typography>
                    <Typography tag="h2" align="center" style={{color: 'white'}}><a href="#partners">Sobre a parceria</a></Typography>
                    <Typography tag="h2" align="center" style={{color: 'white'}}><a href="#questions">Perguntas frequentes</a></Typography>
                </S.HeaderMenuItems>
            )
        }
        </>
    )
}
