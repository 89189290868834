import {
  GET_WIDGET_TOKEN_FAILURE,
  GET_WIDGET_TOKEN_SUCCESS,
  GET_WIDGET_TOKEN_REQUEST,
} from "redux/constants/Payment/Data";

const initialState = {
  loading: false,
  error: false,
  payload: {},
};

const Data = (state = initialState, action) => {
  switch (action.type) {
    case GET_WIDGET_TOKEN_REQUEST:
      return {
        payload: {},
      };
    case GET_WIDGET_TOKEN_SUCCESS:
      return {
        payload: action.payload.dados,
      };
    case GET_WIDGET_TOKEN_FAILURE:
      return {
        payload: {},
      };
    default:
      return state;
  }
};

export default Data;
