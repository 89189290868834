import React from 'react'
import WhiteLogo from 'assets/images/white-logo.svg'
import {Image} from 'components/common'
import * as S from './styled'

export const HeaderNotFoundDesktop = () => {
    return (
        <>
            <S.HeaderBase primaryColor={'#FF6338'} secondaryColor={'#3f12ff'}>
                <S.Header>
                    <S.HeaderConnect>
                        <Image src={WhiteLogo} alt="white-logo" width={163}/>
                    </S.HeaderConnect>
                    <S.HeaderMenu>
                    </S.HeaderMenu>
                </S.Header>
            </S.HeaderBase>
        </>
    )
}