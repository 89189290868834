import styled from 'styled-components'

const sizeOptions = (size) => {
  switch (size) {
    case 'sm':
      return '2rem'
    case 'md':
      return '3.5rem'
    case 'lg':
      return '5rem'
    default:
      return '3.5rem'
  }
}

export const StyledSpinner = styled.div`
  margin: 0px auto 10px auto;
  max-height: 100%;
  position: relative;
  width: ${(props) => sizeOptions(props.spinnerSize)};

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`
