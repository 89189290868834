/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { Form, Row, Col, TextInput, Button } from "components/common";
import { useSelector } from "react-redux";
import { useRegistration } from "hooks";
import { personalDataSchema } from "utils/schemas";
import { masks } from "utils/mask";

export const PersonalData = () => {
  const registration = useRegistration();
  const history = useHistory();

  const {
    partnerData: { payload },
    data: { payload: dataFormGlobalStore },
  } = useSelector(({ registration }) => registration);

  const handleSubmit = () => history.push("/payment-flow/address");

  const formik = useFormik({
    initialValues: {
      name: dataFormGlobalStore.name || "",
      lastName: dataFormGlobalStore.lastName || "",
      email: dataFormGlobalStore.email || "",
      cpf: dataFormGlobalStore.cpf || "",
      income: dataFormGlobalStore.income || "",
      birthdate: dataFormGlobalStore.birthdate || "",
      phoneNumber: dataFormGlobalStore.phoneNumber || "",
      mothersName: dataFormGlobalStore.mothersName || "",
    },
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: handleSubmit,
    validationSchema: () => personalDataSchema,
  });

  useEffect(() => {
    if (!isEmpty(payload)) registration.fillRegistration(payload, formik);
    window.scrollTo(0, 0);
  }, [payload]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row gutterWidth={40}>
        <Col xs={12} md={6}>
          <TextInput
            id="name"
            label="Nome*"
            type="text"
            name="name"
            value={formik.values.name}
            onChange={(event) => registration.onChange(event, formik)}
            onBlur={formik.handleBlur}
            errorMessage={(formik.touched.name && formik.errors.name) || ""}
            placeholder="Digite seu nome"
            maxLength={"255"}
          />
        </Col>
        <Col xs={12} md={6}>
          <TextInput
            id="name"
            label="Sobrenome*"
            type="text"
            name="lastName"
            value={formik.values.lastName}
            onChange={(event) => registration.onChange(event, formik)}
            onBlur={formik.handleBlur}
            errorMessage={(formik.touched.lastName && formik.errors.lastName) || ""}
            placeholder="Digite seu sobrenome"
            maxLength={"255"}
          />
        </Col>
      </Row>
      <Row gutterWidth={40}>
        <Col xs={12} md={6}>
          <TextInput
            id="email"
            label="E-mail*"
            type="text"
            name="email"
            value={formik.values.email}
            onChange={(event) => registration.onChange(event, formik)}
            onBlur={formik.handleBlur}
            errorMessage={(formik.touched.email && formik.errors.email) || ""}
            placeholder="Digite seu e-mail"
            maxLength={"64"}
          />
        </Col>
        <Col xs={12} md={6}>
          <TextInput
            id="cpf"
            label="CPF*"
            type="text"
            name="cpf"
            value={formik.values.cpf}
            onChange={(event) => registration.onChange(event, formik)}
            onBlur={formik.handleBlur}
            placeholder="Digite seu CPF"
            errorMessage={(formik.touched.cpf && formik.errors.cpf) || ""}
            maskInput={{
              mask: masks.cpf.mask,
              maskPlaceholder: "",
            }}
          />
        </Col>
      </Row>
      <Row gutterWidth={40}>
        <Col xs={12} md={6}>
          <TextInput
            id="income"
            label="Renda*"
            type="text"
            name="income"
            value={formik.values.income}
            onChange={(event) => registration.onChange(event, formik)}
            onBlur={formik.handleBlur}
            errorMessage={(formik.touched.income && formik.errors.income) || ""}
            placeholder="Digite sua renda"
            maxLength={"20"}
            currency
          />
        </Col>
        <Col xs={12} md={6}>
          <TextInput
            id="phoneNumber"
            label="Número de celular*"
            type="text"
            name="phoneNumber"
            value={formik.values.phoneNumber}
            onChange={(event) => registration.onChange(event, formik)}
            onBlur={formik.handleBlur}
            errorMessage={
              (formik.touched.phoneNumber && formik.errors.phoneNumber) || ""
            }
            placeholder="(00) 00000-0000"
            maskInput={{
              mask: masks.cel.mask,
              maskPlaceholder: "",
            }}
          />
        </Col>
      </Row>
      <Row gutterWidth={40}>
        <Col xs={12} md={6}>
          <TextInput
            id="birthdate"
            label="Data de nascimento*"
            type="text"
            name="birthdate"
            value={formik.values.birthdate}
            onChange={(event) => registration.onChange(event, formik)}
            onBlur={formik.handleBlur}
            placeholder="Digite a data de nascimento"
            errorMessage={
              (formik.touched.birthdate && formik.errors.birthdate) || ""
            }
            maskInput={{
              mask: masks.data.mask,
              maskPlaceholder: "",
            }}
          />
        </Col>
        <Col xs={12} md={6}>
          <TextInput
            id="mothersName"
            label="Nome completo de sua mãe*"
            type="text"
            name="mothersName"
            value={formik.values.mothersName}
            onChange={(event) => registration.onChange(event, formik)}
            onBlur={formik.handleBlur}
            placeholder="Digite o nome de sua mãe"
            errorMessage={
              (formik.touched.mothersName && formik.errors.mothersName) || ""
            }
            maxLength={"255"}
          />
        </Col>
      </Row>
      <Row gutterWidth={40} direction="row-reverse">
        <Col xs={12} sm={4}>
          <Button
            id="btn_save_account"
            type="submit"
            disabled={!formik.isValid}
            fullWidth={true}
          >
            Próximo Passo
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
