import React from "react";
import PropTypes from "prop-types";
import { Image } from "components/common";
import CheckboxNotSelected from "assets/icons/Checkbox.svg";
import CheckboxSelected from "assets/icons/CheckboxSelected.svg";

import * as S from "./styled";

export const Checkbox = ({ children, spacing, onChange, value }) => {
  return (
    <S.CheckboxWrapper
      id={value}
      spacing={spacing}
      onClick={() => onChange(value)}
    >
      <Image
        src={value ? CheckboxSelected : CheckboxNotSelected}
        alt="checkbox"
      />
      <span>{children}</span>
    </S.CheckboxWrapper>
  );
};

Checkbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool,
  children: PropTypes.node.isRequired,
  spacing: PropTypes.oneOf([
    "",
    "min",
    "xs",
    "smx",
    "sm",
    "sml",
    "base",
    "mdx",
    "md",
    "mdl",
    "lgx",
    "lg",
    "lgl",
    "max",
  ]),
};

Checkbox.defaultProps = {
  spacing: "sm",
  value: false,
};
