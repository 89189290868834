import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    outline: 0;
    padding: 0;
  }

  html {
    font-size: 62.5%;
    height: 100%;
    -ms-overflow-style: scrollbar;
  }

  body {
    font-family: ${({ theme }) => theme.fontFamily.base};
    font-size: ${({ theme }) => theme.fontSize.md};
    display: flex;
		min-height: 100vh;
	  flex-direction: row;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    line-height: ${({ theme }) => theme.lineHeight.heading};
  }

  b,
  strong {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }

  p {
    line-height: ${({ theme }) => theme.lineHeight.base};
  }

  input ,
  select {
    appearance: none;
  }

  ul {
    list-style: none;
  }

  #app, #root { /* stylelint-disable-line */
    display: flex;
		min-height: 100vh;
	  flex-direction: row;
    width: 100%;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid #D4DCDF;
    box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  .row-block > * {
    margin-bottom:  ${({ theme }) => theme.spacing.sm};
    margin-right:  ${({ theme }) => theme.spacing.sm};
  }
`
