import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import { PurchaseInfo, MiniPurchaseInfo } from "components/common";
import Header from "./Header";
import Footer from "./Footer";
import * as S from "./styled";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

export const CompanyRegisterTemplate = ({ children }) => {
  const { step } = useParams();

  const [open, setOpen] = useState(false);

  const stepNumber = () => {
    switch (step) {
      case "company-data":
        return 1;
      case "contact":
        return 2;
      case "address":
        return 3;
      case "payment":
        return 4;
      default:
        return 1;
    }
  };

  const handleChangeOpen = () => setOpen(!open);

  return (
    <S.Container>
      <Header />
      <S.ProgressBar step={stepNumber()} />
      <S.Content>{children}</S.Content>
      <Footer step={step} />
      {step !== "confirmation" && (
        <S.PurchaseInfoWrapper open={open}>
          {(!open && <MiniPurchaseInfo onOpen={handleChangeOpen} />) || (
            <PurchaseInfo onOpen={handleChangeOpen} />
          )}
        </S.PurchaseInfoWrapper>
      )}
    </S.Container>
  );
};
