import React, { useState, useEffect } from "react";
import { Image, Typography, Button } from "components/common";
import HeaderLogo from "assets/images/conectcar-logo.svg";
import ShareIcon from "assets/icons/share.svg";
import DoneCircle from "assets/icons/done-circle.svg";
import * as S from "./styled";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import formatDate from "utils/format/date"
import { useHistory } from "react-router-dom";

export const Confirmation = () => {
  const [planSelected, setPlanSelected] = useState({});
  const history = useHistory();

  const {
    data: { payload: orderData },
  } = useSelector(({ order }) => order);

  const {
    selected: { payload: lp },
  } = useSelector(({ landingPage }) => landingPage);

  const {
    data: { payload },
  } = useSelector(({ registration }) => registration);

  useEffect(() => {
    if (!isEmpty(lp)) {
      const newPlanSelected = lp.ofertas[0].planos[payload.indexSelectedOrder];
      setPlanSelected(newPlanSelected);
    }
  }, [lp])
  

  return (
    <S.Container>
      <S.Card>
        <S.CardRow>
          <Image src={HeaderLogo} alt="header-logo" />
          {/* <Image src={ShareIcon} alt="share" className="btn-share" /> */}
        </S.CardRow>
        <S.CardContent>
          <Image src={DoneCircle} alt="done-circle" spacing="smx" />
          <Typography tag="h3" variant="primary" spacing="smx">
            Compra realizada com sucesso!
          </Typography>
          <Typography tag="support" variant="primary" spacing="smx">
            {formatDate(new Date(), 'dd/MM/yyyy HH:mm:ss')}
          </Typography>
          <Typography tag="h6" variant="primary" spacing="mdx">
            {planSelected?.valorAdesaoFormatado}
          </Typography>
        </S.CardContent>

        <S.CardContent>
          <S.CardRow>
            <Typography tag="h4" variant="secondary" spacing="sml">
              Número do pedido:
            </Typography>
            <Typography tag="default" variant="secondary" spacing="sml">
              {orderData.numeroPedido}
            </Typography>
          </S.CardRow>
          <S.CardRow>
            <Typography tag="h4" variant="secondary" spacing="sml">
              Forma de pagamento:
            </Typography>
            <Typography tag="default" variant="secondary" spacing="sml">
              Cartão de crédito
            </Typography>
          </S.CardRow>
          <S.CardRow>
            <Typography tag="h4" variant="secondary" spacing="sml">
              Status:
            </Typography>
            <Typography
              tag="default"
              variant="secondary"
              spacing="sml"
              style={{ color: "#1EBBA4" }}
            >
              Realizado
            </Typography>
          </S.CardRow>
        </S.CardContent>
        <S.Action>
          <Button id="btn_save_account" type="button" onClick={() => history.push(`/?lp=${lp.dns}`) }>
            OK
          </Button>
        </S.Action>
      </S.Card>
      <S.CardFooter>
        ConectCar Soluções de Mobilidade Eletrônica S/A CNPJ
        <br />
        CNPJ: 16.577.631/0002-99 <br />
        Av. Presidente Juscelino Kubitschek, nº 1830 - 6º andar - Torre III
        <br />
        Vila Nova Conceição - São Paulo-SP - CEP 04543-000
      </S.CardFooter>
    </S.Container>
  );
};
