import styled from "styled-components";
import breakpoints from "utils/mediaQueries/media";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const Card = styled.div`
  border: 1px solid #f7f2ed;
  padding: 30px 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: none;
  background-color: #ffffff;
  width: 100%;

  @media (min-width: ${breakpoints.sm}) {
    width: 455px;
  }
`;

export const CardFooter = styled.div`
  background-color: #333333;
  padding: 17px 20px 17px 14px;
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #ffffff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 100%;

  @media (min-width: ${breakpoints.sm}) {
    width: 455px;
  }
`;

export const CardRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .btn-share {
    cursor: pointer;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  border-bottom: 1px solid #f7f7f7;
`;

export const Action = styled.div`
  margin-top: 32px;
  display: flex;
  width: 100%;
  justify-content: center;
  button {
    min-height: 40px;
    width: 167px;
  }

  @media (min-width: ${breakpoints.sm}) {
    button {
      min-height: 54px;
      width: 201px;
    }
  }
`;
