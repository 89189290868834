import React from 'react';
import { 
    MainContainer, 
    Container, 
    Title
} from './styled';

import { Row, Col} from 'components/common';

export const NotFoundDesktop = () => {
    return (
        <>
            <MainContainer id="not_found">
                <Container>
                    <Row>
                        <Col sm={12}>
                            <Title>Infelizmente não conseguimos encontrar a pagina solicitada</Title>
                        </Col>
                    </Row>
                </Container>
            </MainContainer>
        </>
    )
}
