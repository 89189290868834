import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { actionSetRegistration } from "redux/actions/Registration/Data";

export const useRegistration = () => {
  const dispatch = useDispatch();
  const {
    data: { payload },
  } = useSelector(({ registration }) => registration);

  const onChange = (event, formik) => {
    formik.handleChange(event);
    dispatch(
      actionSetRegistration({
        ...payload,
        [event.target.name]: event.target.value,
      })
    );
  };

  const setProp = (key, value, formik) => {
    formik.setFieldValue(key, value);
    dispatch(
      actionSetRegistration({
        ...payload,
        [key]: value,
      })
    );
  };

  const fillRegistration = (registrationObj, formik) => {
    if (!isEmpty(registrationObj)) {
      formik.setFieldValue("btnText", registrationObj.btnText);
      formik.setFieldValue("btnText", registrationObj.btnText);
      formik.setFieldValue("tag", registrationObj.tag);
      formik.setFieldValue("title", registrationObj.title);
      formik.setFieldValue("supportText", registrationObj.supportText);
    }
  };

  return {
    onChange,
    fillRegistration,
    setProp,
  };
};
