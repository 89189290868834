/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { Form, Row, Col, TextInput, Button, Image } from "components/common";
import { useSelector } from "react-redux";
import { useRegistration } from "hooks";
import { contactSchema } from "utils/schemas";
import { masks } from "utils/mask";
import LeftArrow from "assets/icons/left-arrow.svg";
import PropTypes from "prop-types";

export const Contact = ({ onBack }) => {
  const registration = useRegistration();
  const history = useHistory();

  const {
    partnerData: { payload },
    data: { payload: dataFormGlobalStore },
  } = useSelector(({ registration }) => registration);

  const handleSubmit = () => history.push("/company-payment-flow/address");

  const formik = useFormik({
    initialValues: {
      contactName: dataFormGlobalStore.contactName || "",
      email: dataFormGlobalStore.email || "",
      contactCompanyCellphone:
        dataFormGlobalStore.contactCompanyCellphone || "",
      contactCompanyPhone: dataFormGlobalStore.contactCompanyPhone || "",
    },
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: handleSubmit,
    validationSchema: () => contactSchema,
  });

  useEffect(() => {
    if (!isEmpty(payload)) registration.fillRegistration(payload, formik);
  }, [payload]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row gutterWidth={40}>
        <Col xs={12} md={6}>
          <TextInput
            id="contactName"
            label="Nome completo*"
            type="text"
            name="contactName"
            value={formik.values.contactName}
            onChange={(event) => registration.onChange(event, formik)}
            onBlur={formik.handleBlur}
            errorMessage={
              (formik.touched.contactName && formik.errors.contactName) || ""
            }
            placeholder="Digite seu nome completo"
            maxLength={"255"}
          />
        </Col>
      </Row>
      <Row gutterWidth={40}>
        <Col xs={12} md={6}>
          <TextInput
            id="email"
            label="E-mail*"
            type="text"
            name="email"
            value={formik.values.email}
            onChange={(event) => registration.onChange(event, formik)}
            onBlur={formik.handleBlur}
            errorMessage={(formik.touched.email && formik.errors.email) || ""}
            placeholder="Digite seu email"
            maxLength={"64"}
          />
        </Col>
      </Row>
      <Row gutterWidth={40}>
        <Col xs={12} md={6}>
          <TextInput
            id="contactCompanyCellphone"
            label="Número de celular*"
            type="text"
            name="contactCompanyCellphone"
            value={formik.values.contactCompanyCellphone}
            onChange={(event) => registration.onChange(event, formik)}
            onBlur={formik.handleBlur}
            errorMessage={
              (formik.touched.contactCompanyCellphone &&
                formik.errors.contactCompanyCellphone) ||
              ""
            }
            placeholder="(00) 00000-0000"
            maskInput={{
              mask: masks.cel.mask,
              maskPlaceholder: "",
            }}
          />
        </Col>
      </Row>
      <Row gutterWidth={40}>
        <Col xs={12} md={6}>
          <TextInput
            id="contactCompanyPhone"
            label="Número de telefone fixo"
            type="text"
            name="contactCompanyPhone"
            value={formik.values.contactCompanyPhone}
            onChange={(event) => registration.onChange(event, formik)}
            onBlur={formik.handleBlur}
            errorMessage={
              (formik.touched.contactCompanyPhone &&
                formik.errors.contactCompanyPhone) ||
              ""
            }
            placeholder="(00) 00000-0000"
            maskInput={{
              mask: masks.tel.mask,
              maskPlaceholder: "",
            }}
          />
        </Col>
      </Row>
      <Row gutterWidth={40} align="center">
        <Col xs={12} sm={6}>
          <Button
            className="btn-back"
            id="btn_forgot_password"
            variant="link-primary"
            onClick={() => onBack()}
          >
            <Image
              src={LeftArrow}
              alt="left-arrow"
              style={{ marginRight: 10 }}
            />
            Etapa anterior
          </Button>
        </Col>
        <Col xs={12} sm={6}>
          <Button
            id="btn_save_account"
            type="submit"
            disabled={!formik.isValid}
            fullWidth={true}
          >
            Próximo Passo
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

Contact.propTypes = {
  onBack: PropTypes.func.isRequired
};

