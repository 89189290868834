import React, { useState } from "react";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import InputMask from "react-input-mask";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import * as S from "./styled";

const TextInput = ({
  className,
  containerId,
  labelClass,
  label,
  type,
  name,
  placeholder,
  disabled,
  value,
  onChange,
  errorMessage,
  onBlur,
  id,
  inputMode,
  size,
  maxLength,
  isCleaner,
  maskInput,
  currency,
  autoComplete,
  variant,
  onClear,
  radius,
  numberFormatProps,
  linkBottom,
  onlyView,
  ...rest
}) => {
  const [typeInput, setType] = useState(type);
  const handleChangeInputPassword = () => {
    if (typeInput === "text") setType("password");

    if (typeInput === "password") setType("text");
  };
  return (
    <S.InputGroupWrapper
      variant={variant}
      radius={radius}
      className={`${className} ${errorMessage ? "error" : ""}`}
      id={containerId}
      {...rest}
    >
      <S.InputContainer variant={variant} onlyView={onlyView} radius={radius}>
        <S.Label
          className={`${labelClass} label-input`}
          htmlFor={id}
          variant={variant}
        >
          {label}
        </S.Label>

        {currency ? (
          <NumberFormat
            fixedDecimalScale={numberFormatProps.fixedDecimalScale}
            decimalScale={numberFormatProps.decimalScale}
            thousandSeparator={numberFormatProps.thousandSeparator}
            decimalSeparator={numberFormatProps.decimalSeparator}
            prefix={numberFormatProps.prefix}
            suffix={numberFormatProps.suffix}
            className="floating"
            onChange={onChange}
            isAllowed={(values) => {
              const { floatValue } = values;
              if (
                numberFormatProps.min !== undefined &&
                numberFormatProps.max !== undefined
              )
                return (
                  floatValue >= numberFormatProps.min &&
                  floatValue <= numberFormatProps.max
                );
              else if (numberFormatProps.min !== undefined)
                return floatValue >= numberFormatProps.min;
              else if (numberFormatProps.max !== undefined)
                return floatValue >= numberFormatProps.min;
              return true;
            }}
            onBlur={onBlur}
            aria-label={name}
            name={name}
            id={id}
            value={value}
            placeholder={placeholder}
            disabled={disabled || onlyView}
            autoComplete={autoComplete}
            allowNegative={false}
          />
        ) : (
          <InputMask
            className="floating"
            type={type === "password" ? typeInput : type}
            name={name}
            id={id}
            placeholder={placeholder}
            disabled={disabled || onlyView}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            inputMode={inputMode}
            maxLength={maxLength}
            size={size}
            mask={maskInput.mask}
            maskChar={maskInput.maskPlaceholder}
            aria-label={name}
            autoComplete={autoComplete}
          />
        )}

        {type === "password" && (
          <S.ShowPassword
            className="icon-input"
            onClick={handleChangeInputPassword}
          >
            {typeInput === "password" ? <MdVisibilityOff /> : <MdVisibility />}
          </S.ShowPassword>
        )}

        {linkBottom && <S.ErrorMessage>{linkBottom}</S.ErrorMessage>}
      </S.InputContainer>

      <S.ErrorMessage>{errorMessage && errorMessage}</S.ErrorMessage>
    </S.InputGroupWrapper>
  );
};

TextInput.propTypes = {
  className: PropTypes.string,
  inputMode: PropTypes.string,
  maxLength: PropTypes.string,
  size: PropTypes.string,
  containerId: PropTypes.string,
  variant: PropTypes.string,
  labelClass: PropTypes.string,
  label: PropTypes.node.isRequired,
  type: PropTypes.string,
  name: PropTypes.string,
  onlyView: PropTypes.bool,
  isCleaner: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onClear: PropTypes.func,
  errorMessage: PropTypes.string,
  currency: PropTypes.bool,
  linkBottom: PropTypes.node,
  maskInput: PropTypes.shape({
    mask: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    maskPlaceholder: PropTypes.string,
  }),
  numberFormatProps: PropTypes.shape({
    decimalScale: PropTypes.number,
    thousandSeparator: PropTypes.string,
    decimalSeparator: PropTypes.string,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    max: PropTypes.number,
    min: PropTypes.number,
    fixedDecimalScale: PropTypes.bool,
  }),
  autoComplete: PropTypes.oneOf([
    "current-password",
    "new-password",
    "on",
    "off",
    "username",
  ]),
  radius: PropTypes.oneOf(["sm", "md", "lg"]),
};

TextInput.defaultProps = {
  radius: "sm",
  className: "",
  inputMode: null,
  maxLength: null,
  isCleaner: false,
  size: null,
  containerId: null,
  variant: "primary",
  labelClass: "",
  name: null,
  placeholder: " ",
  linkBottom: null,
  disabled: false,
  type: "text",
  value: "",
  id: null,
  onChange: () => {},
  onClear: () => {},
  onBlur: () => {},
  errorMessage: "",
  currency: false,
  onlyView: false,
  maskInput: {
    mask: "",
    maskPlaceholder: "",
  },
  autoComplete: "off",
  numberFormatProps: {
    decimalScale: 2,
    thousandSeparator: ".",
    decimalSeparator: ",",
    prefix: "R$ ",
    suffix: "",
    fixedDecimalScale: true,
    min: -999999,
    max: 9999999,
  },
};

export { TextInput };
