import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex: 5;
  flex-direction: column;
  overflow: none;
`

export const Main = styled.main`
  display: flex;
  flex-direction: row;
`
export const Content = styled.article`
  background-color: ${({ theme }) => theme.colors.offwhite.base};
  flex: 4;
  min-height: 85vh;
  padding: ${({ theme }) => `${theme.spacing.mdx} ${theme.spacing.max}`};
`
