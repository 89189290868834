import styled from 'styled-components'
import breakpoints from "../../../../utils/mediaQueries/media";

export const Header = styled.header`
  align-items: center;
  background-color: ${(props) => props.primaryColor };
  display: none;
  height: 10vh;
  justify-content: flex-start;
  padding: 40px 17px;
  width: 100%;
  
  @media(max-width: ${breakpoints.lg}) {
    display: flex;
  }
`
export const HeaderConnect = styled.div`
  margin-right: 17px;
`

export const HeaderPartner = styled.div`
  padding-left: 19px;
  border-left: 1px solid ${({ theme }) => theme.colors.tertiary.light};
`

export const HeaderMenu = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  position: absolute;
  right: 17px;
`

export const HeaderMenuItems = styled.div`
  background-color: #2A3037;
  color: ${({ theme }) => theme.colors.white};
  height: fit-content;
  padding-top: 50px;
  padding-bottom: 46px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  & h2 {
    margin-top:24px;
  }
  & img {
    position: absolute;
    top: 30px;
    right: 20px;
  }
  
  & a {
    color: #ffffff;
    text-decoration:none;
  }
`
