import styled from 'styled-components'
import { pxToRem } from 'utils/scales/converter'

export const SnackbarCheckbox = styled.input.attrs({
  type: 'checkbox',
  id: 'close'
})`
  border: 0;
  bottom: ${pxToRem(-70)};
  display: none;
  position: absolute;
  z-index: 10;

  :checked ~ .snackbar,
  :checked,
  :checked + label {
    animation-delay: 0s;
    animation-name: snackbar-hide;
  }

  @keyframes snackbar-hide {
    0%,
    7.5% {
      bottom: ${pxToRem(30)};
    }

    5%,
    10% {
      bottom: ${pxToRem(35)};
    }

    100% {
      bottom: ${pxToRem(-130)};
    }
  }
`
