import React from 'react'

import PropTypes from 'prop-types'

import * as S from './styled'

export const Row = ({
  children,
  className,
  align,
  justify,
  spacing,
  direction,
  ...rest
}) => (
  <S.RowWrapper
    spacing={spacing}
    align={align}
    justify={justify}
    className={className}
    direction={direction}
    {...rest}
  >
    {children}
  </S.RowWrapper>
)

Row.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  direction: PropTypes.string,
  align: PropTypes.oneOf(['normal', 'start', 'center', 'end', 'stretch']),
  justify: PropTypes.oneOf([
    'start',
    'center',
    'end',
    'between',
    'around',
    'initial',
    'inherit'
  ]),
  spacing: PropTypes.oneOf([
    '',
    'min',
    'xs',
    'smx',
    'sm',
    'sml',
    'base',
    'mdx',
    'md',
    'mdl',
    'lgx',
    'lg',
    'lgl',
    'max'
  ])
}

Row.defaultProps = {
  className: null,
  align: 'normal',
  justify: 'start',
  spacing: '',
  direction: 'row'
}
