/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import {
  Form,
  Row,
  Col,
  TextInput,
  Button,
  Autocomplete,
  Typography,
} from "components/common";
import { useSelector } from "react-redux";
import { useRegistration } from "hooks";
import { companyDataSchema } from "utils/schemas";
import { masks } from "utils/mask";


export const CompanyData = () => {
  const registration = useRegistration();
  const history = useHistory();

  const {
    partnerData: { payload },
    data: { payload: dataFormGlobalStore },
  } = useSelector(({ registration }) => registration);

  const handleSubmit = () => history.push("/company-payment-flow/contact");

  const formik = useFormik({
    initialValues: {
      cnpj: dataFormGlobalStore.cnpj || "",
      socialName: dataFormGlobalStore.socialName || "",
      invoicing: dataFormGlobalStore.invoicing || "",
      dateOfIncorporation: dataFormGlobalStore.dateOfIncorporation || "",
      legalNature: dataFormGlobalStore.legalNature || "",
      name: dataFormGlobalStore.name || "",
      lastName: dataFormGlobalStore.lastName || "",
      cpf: dataFormGlobalStore.cpf || "",
      representativeSince: dataFormGlobalStore.representativeSince || "",
      post: dataFormGlobalStore.post || "",
    },
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: handleSubmit,
    validationSchema: () => companyDataSchema,
  });

  useEffect(() => {
    if (!isEmpty(payload)) registration.fillRegistration(payload, formik);
  }, [payload]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row gutterWidth={40}>
        <Col xs={12} md={6}>
          <TextInput
            id="cnpj"
            label="CNPJ*"
            type="text"
            name="cnpj"
            value={formik.values.cnpj}
            onChange={(event) => registration.onChange(event, formik)}
            onBlur={formik.handleBlur}
            errorMessage={(formik.touched.cnpj && formik.errors.cnpj) || ""}
            placeholder="Digite o CNPJ"
            maskInput={{
              mask: masks.cnpj.mask,
              maskPlaceholder: "",
            }}
          />
        </Col>
        <Col xs={12} md={6}>
          <TextInput
            id="socialName"
            label="Razão Social*"
            type="text"
            name="socialName"
            value={formik.values.socialName}
            onChange={(event) => registration.onChange(event, formik)}
            onBlur={formik.handleBlur}
            errorMessage={
              (formik.touched.socialName && formik.errors.socialName) || ""
            }
            placeholder="Digite o nome comercial"
            maxLength={"255"}
          />
        </Col>
      </Row>
      <Row gutterWidth={40}>
        <Col xs={12} md={6}>
          <TextInput
            id="invoicing"
            label="Faturamento"
            type="text"
            name="invoicing"
            value={formik.values.invoicing}
            onChange={(event) => registration.onChange(event, formik)}
            onBlur={formik.handleBlur}
            placeholder="Ex: 100.000,00"
            errorMessage={
              (formik.touched.invoicing && formik.errors.invoicing) || ""
            }
            maskInput={{
              mask: masks.currency.mask,
              maskPlaceholder: "",
            }}
            maxLength={"20"}
            currency
          />
        </Col>
        <Col xs={12} md={6}>
          <TextInput
            id="dateOfIncorporation"
            label="Data de constituição*"
            type="text"
            name="dateOfIncorporation"
            value={formik.values.dateOfIncorporation}
            onChange={(event) => registration.onChange(event, formik)}
            onBlur={formik.handleBlur}
            placeholder="Digite a data"
            errorMessage={
              (formik.touched.dateOfIncorporation &&
                formik.errors.dateOfIncorporation) ||
              ""
            }
            maskInput={{
              mask: masks.data.mask,
              maskPlaceholder: "",
            }}
          />
        </Col>
      </Row>
      <Row gutterWidth={40}>
        <Col xs={12} md={6}>
          <Autocomplete
            label="Natureza Jurídica*"
            id="legalNature"
            name="legalNature"
            value={formik.values.legalNature}
            spacing="lgl"
            placeholder="Selecionar a natureza jurídica"
            onBlur={() => {
              formik.setFieldTouched(
                "legalNature",
                true,
                !formik.errors.kindOfCriterionOffers
              );
            }}
            onChange={(value) =>
              registration.setProp("legalNature", value, formik)
            }
            options={[
              {
                value: "101-5 - Órgão Público do Poder Executivo Federal",
                label: "101-5 - Órgão Público do Poder Executivo Federal"
              },
              {
                value: "102-3 - Órgão Público do Poder Executivo Estadual ou do Distrito Federal",
                label: "102-3 - Órgão Público do Poder Executivo Estadual ou do Distrito Federal"
              },
              {
                value: "103-1 - Órgão Público do Poder Executivo Municipal",
                label: "103-1 - Órgão Público do Poder Executivo Municipal"
              },
              {
                value: "104-0 - Órgão Público do Poder Legislativo Federal",
                label: "104-0 - Órgão Público do Poder Legislativo Federal"
              },
              {
                value: "105-8 - Órgão Público do Poder Legislativo Estadual ou do Distrito Federal",
                label: "105-8 - Órgão Público do Poder Legislativo Estadual ou do Distrito Federal"
              },
              {
                value: "106-6 - Órgão Público do Poder Legislativo Municipal",
                label: "106-6 - Órgão Público do Poder Legislativo Municipal"
              },
              {
                value: "107-4 - Órgão Público do Poder Judiciário Federal",
                label: "107-4 - Órgão Público do Poder Judiciário Federal"
              },
              {
                value: "108-2 - Órgão Público do Poder Judiciário Estadual",
                label: "108-2 - Órgão Público do Poder Judiciário Estadual"
              },
              {
                value: "110-4 - Autarquia Federal",
                label: "110-4 - Autarquia Federal"
              },
              {
                value: "111-2 - Autarquia Estadual ou do Distrito Federal",
                label: "111-2 - Autarquia Estadual ou do Distrito Federal"
              },
              {
                value: "112-0 - Autarquia Municipal",
                label: "112-0 - Autarquia Municipal"
              },
              {
                value: "113-9 - Fundação Pública de Direito Público Federal",
                label: "113-9 - Fundação Pública de Direito Público Federal"
              },
              {
                value: "114-7 - Fundação Pública de Direito Público Estadual ou do Distrito Federal",
                label: "114-7 - Fundação Pública de Direito Público Estadual ou do Distrito Federal"
              },
              {
                value: "115-5 - Fundação Pública de Direito Público Municipal",
                label: "115-5 - Fundação Pública de Direito Público Municipal"
              },
              {
                value: "116-3 - Órgão Público Autônomo Federal",
                label: "116-3 - Órgão Público Autônomo Federal"
              },
              {
                value: "117-1 - Órgão Público Autônomo Estadual ou do Distrito Federal",
                label: "117-1 - Órgão Público Autônomo Estadual ou do Distrito Federal"
              },
              {
                value: "118-0 - Órgão Público Autônomo Municipal",
                label: "118-0 - Órgão Público Autônomo Municipal"
              },
              {
                value: "119-8 - Comissão Polinacional",
                label: "119-8 - Comissão Polinacional"
              },
              {
                value: "121-0 - Consórcio Público de Direito Público (Associação Pública)",
                label: "121-0 - Consórcio Público de Direito Público (Associação Pública)"
              },
              {
                value: "122-8 - Consórcio Público de Direito Privado",
                label: "122-8 - Consórcio Público de Direito Privado"
              },
              {
                value: "123-6 - Estado ou Distrito Federal",
                label: "123-6 - Estado ou Distrito Federal"
              },
              {
                value: "124-4 - Município",
                label: "124-4 - Município"
              },
              {
                value: "125-2 - Fundação Pública de Direito Privado Federal",
                label: "125-2 - Fundação Pública de Direito Privado Federal"
              },
              {
                value: "126-0 - Fundação Pública de Direito Privado Estadual ou do Distrito Federal",
                label: "126-0 - Fundação Pública de Direito Privado Estadual ou do Distrito Federal"
              },
              {
                value: "127-9 - Fundação Pública de Direito Privado Municipal",
                label: "127-9 - Fundação Pública de Direito Privado Municipal"
              },
              {
                value: "128-7 - Fundo Público da Administração Indireta Federal",
                label: "128-7 - Fundo Público da Administração Indireta Federal"
              },
              {
                value: "129-5 - Fundo Público da Administração Indireta Estadual ou do Distrito Federal",
                label: "129-5 - Fundo Público da Administração Indireta Estadual ou do Distrito Federal"
              },
              {
                value: "130-9 - Fundo Público da Administração Indireta Municipal",
                label: "130-9 - Fundo Público da Administração Indireta Municipal"
              },
              {
                value: "131-7 - Fundo Público da Administração Direta Federal",
                label: "131-7 - Fundo Público da Administração Direta Federal"
              },
              {
                value: "132-5 - Fundo Público da Administração Direta Estadual ou do Distrito Federal",
                label: "132-5 - Fundo Público da Administração Direta Estadual ou do Distrito Federal"
              },
              {
                value: "133-3 - Fundo Público da Administração Direta Municipal",
                label: "133-3 - Fundo Público da Administração Direta Municipal"
              },
              {
                value: "134-1 - União",
                label: "134-1 - União"
              },
              {
                value: "201-1 - Empresa Pública",
                label: "201-1 - Empresa Pública"
              },
              {
                value: "203-8 - Sociedade de Economia Mista",
                label: "203-8 - Sociedade de Economia Mista"
              },
              {
                value: "204-6 - Sociedade Anônima Aberta",
                label: "204-6 - Sociedade Anônima Aberta"
              },
              {
                value: "205-4 - Sociedade Anônima Fechada",
                label: "205-4 - Sociedade Anônima Fechada"
              },
              {
                value: "206-2 - Sociedade Empresária Limitada",
                label: "206-2 - Sociedade Empresária Limitada"
              },
              {
                value: "207-0 - Sociedade Empresária em Nome Coletivo",
                label: "207-0 - Sociedade Empresária em Nome Coletivo"
              },
              {
                value: "208-9 - Sociedade Empresária em Comandita Simples",
                label: "208-9 - Sociedade Empresária em Comandita Simples"
              },
              {
                value: "209-7 - Sociedade Empresária em Comandita por Ações",
                label: "209-7 - Sociedade Empresária em Comandita por Ações"
              },
              {
                value: "212-7 - Sociedade em Conta de Participação",
                label: "212-7 - Sociedade em Conta de Participação"
              },
              {
                value: "213-5 - Empresário (Individual)",
                label: "213-5 - Empresário (Individual)"
              },
              {
                value: "214-3 - Cooperativa",
                label: "214-3 - Cooperativa"
              },
              {
                value: "215-1 - Consórcio de Sociedades",
                label: "215-1 - Consórcio de Sociedades"
              },
              {
                value: "216-0 - Grupo de Sociedades",
                label: "216-0 - Grupo de Sociedades"
              },
              {
                value: "217-8 - Estabelecimento, no Brasil, de Sociedade Estrangeira",
                label: "217-8 - Estabelecimento, no Brasil, de Sociedade Estrangeira"
              },
              {
                value: "219-4 - Estabelecimento, no Brasil, de Empresa Binacional Argentino-Brasileira",
                label: "219-4 - Estabelecimento, no Brasil, de Empresa Binacional Argentino-Brasileira"
              },
              {
                value: "221-6 - Empresa Domiciliada no Exterior",
                label: "221-6 - Empresa Domiciliada no Exterior"
              },
              {
                value: "222-4 - Clube/Fundo de Investimento",
                label: "222-4 - Clube/Fundo de Investimento"
              },
              {
                value: "223-2 - Sociedade Simples Pura",
                label: "223-2 - Sociedade Simples Pura"
              },
              {
                value: "224-0 - Sociedade Simples Limitada",
                label: "224-0 - Sociedade Simples Limitada"
              },
              {
                value: "225-9 - Sociedade Simples em Nome Coletivo",
                label: "225-9 - Sociedade Simples em Nome Coletivo"
              },
              {
                value: "226-7 - Sociedade Simples em Comandita Simples",
                label: "226-7 - Sociedade Simples em Comandita Simples"
              },
              {
                value: "227-5 - Empresa Binacional",
                label: "227-5 - Empresa Binacional"
              },
              {
                value: "228-3 - Consórcio de Empregadores",
                label: "228-3 - Consórcio de Empregadores"
              },
              {
                value: "229-1 - Consórcio Simples",
                label: "229-1 - Consórcio Simples"
              },
              {
                value: "230-5 - Empresa Individual de Responsabilidade Limitada (de Natureza Empresária)",
                label: "230-5 - Empresa Individual de Responsabilidade Limitada (de Natureza Empresária)"
              },
              {
                value: "231-3 - Empresa Individual de Responsabilidade Limitada (de Natureza Simples)",
                label: "231-3 - Empresa Individual de Responsabilidade Limitada (de Natureza Simples)"
              },
              {
                value: "232-1 – Sociedade Unipessoal de Advogados",
                label: "232-1 – Sociedade Unipessoal de Advogados"
              },
              {
                value: "233-0 – Cooperativas de Consumo",
                label: "233-0 – Cooperativas de Consumo"
              },
              {
                value: "303-4 - Serviço Notarial e Registral (Cartório)",
                label: "303-4 - Serviço Notarial e Registral (Cartório)"
              },
              {
                value: "306-9 - Fundação Privada",
                label: "306-9 - Fundação Privada"
              },
              {
                value: "307-7 - Serviço Social Autônomo",
                label: "307-7 - Serviço Social Autônomo"
              },
              {
                value: "308-5 - Condomínio Edilício",
                label: "308-5 - Condomínio Edilício"
              },
              {
                value: "310-7 - Comissão de Conciliação Prévia",
                label: "310-7 - Comissão de Conciliação Prévia"
              },
              {
                value: "311-5 - Entidade de Mediação e Arbitragem",
                label: "311-5 - Entidade de Mediação e Arbitragem"
              },
              {
                value: "313-1 - Entidade Sindical",
                label: "313-1 - Entidade Sindical"
              },
              {
                value: "320-4 - Estabelecimento, no Brasil, de Fundação ou Associação Estrangeiras",
                label: "320-4 - Estabelecimento, no Brasil, de Fundação ou Associação Estrangeiras"
              },
              {
                value: "321-2 - Fundação ou Associação Domiciliada no Exterior",
                label: "321-2 - Fundação ou Associação Domiciliada no Exterior"
              },
              {
                value: "322-0 - Organização Religiosa",
                label: "322-0 - Organização Religiosa"
              },
              {
                value: "323-9 - Comunidade Indígena",
                label: "323-9 - Comunidade Indígena"
              },
              {
                value: "324-7 - Fundo Privado",
                label: "324-7 - Fundo Privado"
              },
              {
                value: "325-5 - Órgão de Direção Nacional de Partido Político",
                label: "325-5 - Órgão de Direção Nacional de Partido Político"
              },
              {
                value: "326-3 - Órgão de Direção Regional de Partido Político",
                label: "326-3 - Órgão de Direção Regional de Partido Político"
              },
              {
                value: "327-1 - Órgão de Direção Local de Partido Político",
                label: "327-1 - Órgão de Direção Local de Partido Político"
              },
              {
                value: "328-0 - Comitê Financeiro de Partido Político",
                label: "328-0 - Comitê Financeiro de Partido Político"
              },
              {
                value: "329-8 - Frente Plebiscitária ou Referendária",
                label: "329-8 - Frente Plebiscitária ou Referendária"
              },
              {
                value: "330-1 - Organização Social (OS)",
                label: "330-1 - Organização Social (OS)"
              },
              {
                value: "331-0 - Demais Condomínios",
                label: "331-0 - Demais Condomínios"
              },
              {
                value: "399-9 - Associação Privada",
                label: "399-9 - Associação Privada"
              },
              {
                value: "401-4 - Empresa Individual Imobiliária",
                label: "401-4 - Empresa Individual Imobiliária"
              },
              {
                value: "402-2 - Segurado Especial",
                label: "402-2 - Segurado Especial"
              },
              {
                value: "408-1 - Contribuinte individual",
                label: "408-1 - Contribuinte individual"
              },
              {
                value: "409-0 - Candidato a Cargo Político Eletivo",
                label: "409-0 - Candidato a Cargo Político Eletivo"
              },
              {
                value: "411-1 - Leiloeiro",
                label: "411-1 - Leiloeiro"
              },
              {
                value: "412-0 - Produtor Rural (Pessoa Física)",
                label: "412-0 - Produtor Rural (Pessoa Física)"
              },
              {
                value: "501-0 - Organização Internacional",
                label: "501-0 - Organização Internacional"
              },
              {
                value: "502-9 - Representação Diplomática Estrangeira",
                label: "502-9 - Representação Diplomática Estrangeira"
              },
              {
                value: "503-7 - Outras Instituições Extraterritoriais",
                label: "503-7 - Outras Instituições Extraterritoriais"
              }
            ]}
            errorMessage={
              (formik.touched.legalNature && formik.errors.legalNature) || ""
            }
          />
        </Col>
      </Row>
      <Row gutterWidth={40}>
        <Col xs={12}>
          <Typography tag="h5" spacing="xs">
            Representante Legal
          </Typography>
        </Col>
      </Row>
      <Row gutterWidth={40}>
        <Col xs={12}>
          <Typography tag="disclaimer" spacing="mdx">
            * Campo de preenchimento obrigatório
          </Typography>
        </Col>
      </Row>
      <Row gutterWidth={40}>
        <Col xs={12} md={6}>
          <TextInput
            id="name"
            label="Nome*"
            type="text"
            name="name"
            value={formik.values.name}
            onChange={(event) => registration.onChange(event, formik)}
            onBlur={formik.handleBlur}
            errorMessage={(formik.touched.name && formik.errors.name) || ""}
            placeholder="Digite o nome do representante legal"
            maxLength={"255"}
          />
        </Col>
        <Col xs={12} md={6}>
          <TextInput
            id="name"
            label="Sobrenome*"
            type="text"
            name="lastName"
            value={formik.values.lastName}
            onChange={(event) => registration.onChange(event, formik)}
            onBlur={formik.handleBlur}
            errorMessage={(formik.touched.lastName && formik.errors.lastName) || ""}
            placeholder="Digite o sobrenome do representante legal"
            maxLength={"255"}
          />
        </Col>
      </Row>
      <Row gutterWidth={40}>
        <Col xs={12} md={6}>
          <TextInput
            id="cpf"
            label="CPF*"
            type="text"
            name="cpf"
            value={formik.values.cpf}
            onChange={(event) => registration.onChange(event, formik)}
            onBlur={formik.handleBlur}
            placeholder="Digite seu CPF"
            errorMessage={(formik.touched.cpf && formik.errors.cpf) || ""}
            maskInput={{
              mask: masks.cpf.mask,
              maskPlaceholder: "",
            }}
          />
        </Col>
        <Col xs={12} md={6}>
          <TextInput
            id="representativeSince"
            label="Representante desde*"
            type="text"
            name="representativeSince"
            value={formik.values.representativeSince}
            onChange={(event) => registration.onChange(event, formik)}
            onBlur={formik.handleBlur}
            placeholder="Ex: 10/01/2021"
            errorMessage={
              (formik.touched.representativeSince &&
                formik.errors.representativeSince) ||
              ""
            }
            maskInput={{
              mask: masks.data.mask,
              maskPlaceholder: "",
            }}
          />
        </Col>
      </Row>
      <Row gutterWidth={40}>
        <Col xs={12}>
          <TextInput
            id="post"
            label="Cargo"
            type="text"
            name="post"
            value={formik.values.post}
            onChange={(event) => registration.onChange(event, formik)}
            onBlur={formik.handleBlur}
            errorMessage={(formik.touched.post && formik.errors.post) || ""}
            placeholder="Digite o o cargo"
            maxLength={"255"}
          />
        </Col>
      </Row>
      <Row gutterWidth={40} direction="row-reverse">
        <Col xs={12} sm={4}>
          <Button
            id="btn_save_account"
            type="submit"
            disabled={!formik.isValid}
            fullWidth={true}
          >
            Próximo Passo
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

