import styled from 'styled-components'
import breakpoints from "../../../../utils/mediaQueries/media";

export const Footer = styled.footer`
  background-color: #2A3037;
  color: ${({ theme }) => theme.colors.black};
  display: grid;
  justify-items: center;
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  height: fit-content;
  padding: 108px 0;
  
  @media(max-width: ${breakpoints.lg}) {
    padding: 57px 0;
  }
  
  ${props => props.error ? 'bottom: 0;position: fixed;width: 100%;text-align: center;' : ''}
`
export const GridContainer = styled.div`
  color: ${({ theme }) => theme.colors.white};
  display: grid;
  align-self: center;
  justify-items: center;
  & > h1,
  h2 {
    margin-bottom: ${({ theme }) => theme.spacing.max};
  }
  
  & > a {
    color: ##FF6338 !important;
  }
  width: 50%;
  
  @media(max-width: ${breakpoints.lg}) {
    width: 90%;
  }
`

export const ImageContainer = styled.div`
    display:flex;
    margin-bottom: 28px;
`

export const FooterConnect = styled.div`
  margin-right: 17px;
`

export const FooterPartner = styled.div`
  padding-left: 19px;
  border-left: 1px solid ${({ theme }) => theme.colors.tertiary.light};
`
