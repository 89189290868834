import { SET_REGISTRATION_DATA } from 'redux/constants/Registration/Data'

const initialState = {
  payload: {}
}

const Data = (state = initialState, action) => {  
  switch (action.type) {
    case SET_REGISTRATION_DATA:      
      return {
        payload: action.payload
      }
    default:
      return state
  }
}

export default Data
