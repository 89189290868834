import styled, { css } from 'styled-components'

const sizes = {
  sm: () => css`
    max-width: ${({ theme }) => theme.container.sm};
  `,

  md: () => css`
    max-width: ${({ theme }) => theme.container.md};
  `,

  lg: () => css`
    max-width: ${({ theme }) => theme.container.lg};
  `,

  xl: () => css`
    max-width: ${({ theme }) => theme.container.xl};
  `,

  fluid: () => css`
    max-width: 100%;
  `
}

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  margin: ${({ margin }) => margin};
  width: 100%;
  ${(props) => sizes[props.size]}
`
