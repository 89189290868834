import styled from 'styled-components'
import breakpoints from "../../../../utils/mediaQueries/media";

export const Container = styled.section`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  padding-bottom: 40px;  
`

export const CentralContainer = styled.div`
    background: ${(props) => props.bgColor};
    border-radius: 18px;
    align-items:center;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 1300px;
    margin-top: 80px;
    padding: 72px 0;
    
    @media (max-width: ${breakpoints.lg}) {
        & h1 {
          font-size: 0.8em;
        }
        width: 90%;
    }
`

export const CTAContainer = styled.div`
    width: 100%;
    padding: 0 15px;
`

export const CentralTags = styled.div`
  background-color: ${(props) => props.bgColor};
  border-radius: 6px;
  color: #FFFFFF;
  height: 31px;
  margin-top: 30px;
  padding: 6px;
  width: fit-content;
`

export const CentralTitle = styled.div`
   color: ${({ theme }) => theme.colors.white};
   font-size: 35px;
   font-weight: 700;
   margin: 8px 0;
   width: 60%;
`

export const CentralButton = styled.div`
    margin-top: 18px;
    width: 100%;
    display: contents;
    
    & button, a {
       background: #004077;
    }
`
