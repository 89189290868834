/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { isEmpty } from "lodash";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Row,
  Col,
  TextInput,
  Button,
  Checkbox,
  Image,
} from "components/common";
import { masks } from "utils/mask";
import { useRegistration } from "hooks";
import { addressSchema } from "utils/schemas";
import { actionGetAddress } from "redux/actions/Address/Data";
import LeftArrow from "assets/icons/left-arrow.svg";

export const Address = ({ onBack, onNext }) => {
  const registration = useRegistration();
  const dispatch = useDispatch();

  const {
    partnerData: { payload },
    data: { payload: dataFormGlobalStore },
  } = useSelector(({ registration }) => registration);

  const {
    data: { payload: dataAddress },
  } = useSelector(({ address }) => address);

  const handleSubmit = () => onNext();

  const formik = useFormik({
    initialValues: {
      zipCode: dataFormGlobalStore.zipCode || "",
      street: dataFormGlobalStore.street || "",
      neighborhood: dataFormGlobalStore.neighborhood || "",
      city: dataFormGlobalStore.city || "",
      state: dataFormGlobalStore.state || "",
      number: dataFormGlobalStore.number || "",
      complement: dataFormGlobalStore.complement || "",
      adhesionAgreement: dataFormGlobalStore.adhesionAgreement || false,
      receiveOffers: dataFormGlobalStore.receiveOffers || false,
      shareData: dataFormGlobalStore.shareData || false,
    },
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: handleSubmit,
    validationSchema: () => addressSchema,
  });

  const setFieldValueCustom = (field, data) =>
    formik.setFieldValue(field, data || "", false);

  const fillAddressFormByStorage = (address) => {
    setFieldValueCustom("zipCode", address.zipCode || formik.values.zipCode);
    setFieldValueCustom("street", address.logradouro);
    setFieldValueCustom("neighborhood", address.bairro);
    setFieldValueCustom("city", address.cidade);
    setFieldValueCustom("state", address.estado);
    setFieldValueCustom("number", dataFormGlobalStore.number || address.number);
    setFieldValueCustom(
      "complement",
      dataFormGlobalStore.complement || address.complement
    );
  };

  useEffect(() => {
    if (!isEmpty(dataAddress)) {
      fillAddressFormByStorage(dataAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAddress]);

  useEffect(() => {
    if (!isEmpty(payload)) registration.fillRegistration(payload, formik);
  }, [payload]);

  const handleChangeZipCode = (event) => {
    registration.onChange(event, formik);
    if (event.target.value.length === 9)
      dispatch(actionGetAddress(event.target.value.replace("-", "")));
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row gutterWidth={40} align="center">
        <Col xs={12} md={6}>
          <TextInput
            id="zipCode"
            label="CEP*"
            type="text"
            name="zipCode"
            value={formik.values.zipCode}
            onChange={handleChangeZipCode}
            onBlur={formik.handleBlur}
            errorMessage={
              (formik.touched.zipCode && formik.errors.zipCode) || ""
            }
            placeholder="Ex: 06710-820"
            maskInput={{
              mask: masks.cep.mask,
              maskPlaceholder: "",
            }}
          />
        </Col>
        <Col xs={12} md={4} style={{ marginBottom: 20 }}>
          <Button
            id="btn_forgot_password"
            variant="link-primary"
            onClick={() =>
              window.open(
                "https://buscacepinter.correios.com.br/app/endereco/index.php",
                "_blank"
              )
            }
          >
            Não sei meu CEP.
          </Button>
        </Col>
      </Row>
      <Row gutterWidth={40}>
        <Col xs={12} md={12}>
          <TextInput
            id="street"
            label="Endereço*"
            type="text"
            name="street"
            value={formik.values.street}
            onChange={(event) => registration.onChange(event, formik)}
            onBlur={formik.handleBlur}
            errorMessage={(formik.touched.street && formik.errors.street) || ""}
            placeholder="Ex: Rua País de Gales"
            maxLength={"255"}
          />
        </Col>
      </Row>

      <Row gutterWidth={40}>
        <Col xs={12} md={4}>
          <TextInput
            id="number"
            label="Número*"
            type="text"
            name="number"
            value={formik.values.number}
            onChange={(event) => registration.onChange(event, formik)}
            onBlur={formik.handleBlur}
            errorMessage={(formik.touched.number && formik.errors.number) || ""}
            placeholder="Digite seu número"
            maskInput={{
              mask: masks.currency.mask,
              maskPlaceholder: "",
            }}
          />
        </Col>
        <Col xs={12} md={4}>
          <TextInput
            id="complement"
            label="Complemento"
            type="text"
            name="complement"
            value={formik.values.complement}
            onChange={(event) => registration.onChange(event, formik)}
            onBlur={formik.handleBlur}
            errorMessage={
              (formik.touched.complement && formik.errors.complement) || ""
            }
            placeholder="Ex: Casa 2"
            maxLength={"255"}
          />
        </Col>
        <Col xs={12} md={4}>
          <TextInput
            id="neighborhood"
            label="Bairro"
            type="text"
            name="neighborhood"
            value={formik.values.neighborhood}
            onChange={(event) => registration.onChange(event, formik)}
            onBlur={formik.handleBlur}
            errorMessage={
              (formik.touched.neighborhood && formik.errors.neighborhood) || ""
            }
            placeholder="Digite seu bairro"
            maxLength={"255"}
          />
        </Col>
      </Row>
      <Row gutterWidth={40}>
        <Col xs={12} md={6}>
          <TextInput
            id="state"
            label="Estado*"
            type="text"
            name="state"
            value={formik.values.state}
            onChange={(event) => registration.onChange(event, formik)}
            onBlur={formik.handleBlur}
            errorMessage={(formik.touched.state && formik.errors.state) || ""}
            disabled="true"
          />
        </Col>
        <Col xs={12} md={6}>
          <TextInput
            id="city"
            label="Cidade*"
            type="text"
            name="city"
            value={formik.values.city}
            onChange={(event) => registration.onChange(event, formik)}
            onBlur={formik.handleBlur}
            errorMessage={(formik.touched.city && formik.errors.city) || ""}
            maxLength={"64"}
            disabled="true"
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6}>
          <Checkbox
            value={formik.values.adhesionAgreement}
            onChange={(value) =>
              registration.setProp("adhesionAgreement", !value, formik)
            }
          >
            Compreendo e concordo com o <strong>Termo de Adesão</strong> e
            <strong> Política de Privacidade</strong> da ConectCar.
          </Checkbox>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <Checkbox
            value={formik.values.receiveOffers}
            onChange={(value) =>
              registration.setProp("receiveOffers", !value, formik)
            }
          >
            Concordo em receber comunicações de ofertas da ConectCar. (Opcional)
          </Checkbox>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <Checkbox
            value={formik.values.shareData}
            onChange={(value) =>
              registration.setProp("shareData", !value, formik)
            }
            spacing="mdl"
          >
            Concordo que a ConectCar compartilhe meus dados com parceiros para
            recebimento de materiais promocionais, informativos e marketing.
            (Opcional)
          </Checkbox>
        </Col>
      </Row>
      <Row gutterWidth={40} align="center">
        <Col xs={12} sm={6}>
          <Button
            className="btn-back"
            id="btn_forgot_password"
            variant="link-primary"
            onClick={() => onBack()}
          >
            <Image
              src={LeftArrow}
              alt="left-arrow"
              style={{ marginRight: 10 }}
            />
            Etapa anterior
          </Button>
        </Col>
        <Col xs={12} sm={6}>
          <Button
            id="btn_save_account"
            type="submit"
            disabled={!formik.isValid}
            fullWidth={true}
          >
            Próximo Passo
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

Address.propTypes = {
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};
