import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { actionSetRegistration } from "redux/actions/Registration/Data";
import { actionSetAdress } from "redux/actions/Address/Data"
import { Button, Image } from "../../../common";
import BlackChecked from "../../../../assets/icons/black-checked.svg";
import EnvironmentVariables from "../../../../environment-variables";
import * as S from "./styled";

export const OfferItem = ({ item, index, corPrimaria, corSecundaria }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    data: { payload },
  } = useSelector(({ registration }) => registration);

  const {
    selected: { payload: lp },
  } = useSelector(({ landingPage }) => landingPage);

  const handleRedirectOrder = (indexParam) => {    
    dispatch(
      actionSetRegistration({        
        indexSelectedOrder: indexParam,
      })
    );
    dispatch(
      actionSetAdress({})
    );
    const type = lp.ofertas[0].planos[index].tipoCliente;
    history.push(
      type === "PARASUAEMPRESA"
        ? "company-payment-flow/company-data"
        : "/payment-flow/personal-data"
    );
  };
  return (
    <>
      <S.OfferItem
        key={index}
        variant={item.planoDestaque ? "popular" : "default"}
        corPrimaria={corPrimaria}
        corSecundaria={corSecundaria}
      >
        {item.planoDestaque && (
          <S.PlanPopular bgColor={corPrimaria}>mais popular</S.PlanPopular>
        )}
        <S.PlanTitle>{item.tituloPlano}</S.PlanTitle>

        <S.PlanPrice defaultColor={corSecundaria}>
          {item.valorPlano}
        </S.PlanPrice>
        <S.PlanTag>{item.textoTag}</S.PlanTag>
        <S.PlanText>{item.textoApoio}</S.PlanText>
        <S.PlanCTA>
          <Button
            style={{
              backgroundColor: item.planoDestaque ? corPrimaria : corSecundaria,
            }}
            onClick={() => handleRedirectOrder(index)}
          >
            Peça o seu!
          </Button>
          <Button variant="borderBlue" href={EnvironmentVariables.ACTIVATE_URL}>
            Ative seu adesivo
          </Button>
        </S.PlanCTA>
        <S.PlanList>
          <ul>
            <li>
              <Image alt="check" src={BlackChecked} />
              Aceito em 100% das rodovias pedagiadas.
            </li>
            <li>
              <Image alt="check" src={BlackChecked} />
              Segurança para o motorista.
            </li>
            <li>
              <Image alt="check" src={BlackChecked} />
              Contratação online, simples e rápida.
            </li>
          </ul>
        </S.PlanList>
      </S.OfferItem>
    </>
  );
};

export const OfferItemEmp = ({ index, corPrimaria, corSecundaria }) => {
  return (
    <>
      <S.OfferItemEmp
        key={index}
        corPrimaria={corPrimaria}
        corSecundaria={corSecundaria}
      >
        <S.PlanTag>12 mensalidades grátis</S.PlanTag>
        <S.PlanTitle>Realize uma simulaçao</S.PlanTitle>

        <S.PlanPrice defaultColor={corSecundaria}>ConectCar Frotas</S.PlanPrice>

        <S.PlanText>Para cada adesão, enviaremos a você um adesivo.</S.PlanText>
        <S.PlanList>
          <ul>
            <li>
              <Image alt="check" src={BlackChecked} />
              Aceito em 100% das rodovias pedagiadas.
            </li>
            <li>
              <Image alt="check" src={BlackChecked} />
              Segurança para o motorista.
            </li>
            <li>
              <Image alt="check" src={BlackChecked} />
              Contratação online, simples e rápida.
            </li>
          </ul>
        </S.PlanList>
      </S.OfferItemEmp>
    </>
  );
};
