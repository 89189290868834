import React, { useEffect, useState } from "react";
import { useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import queryString from "querystring";
import * as S from "./styled";

import { actionSetRegistration } from "redux/actions/Registration/Data";

import { HeaderMobile } from "./HeaderMobile";
import { HeaderDesktop } from "./HeaderDesktop";
import { HeaderNotFoundDesktop } from "./HeaderNotFoundDesktop";
import { HeaderNotFoundMobile } from "./HeaderNotFoundMobile";
import { MainPanelMobile } from "./MainPanelMobile";
import { MainPanelDesktop } from "./MainPanelDesktop";
import { BenefitsMobile } from "./BenefitsMobile";
import { BenefitsDesktop } from "./BenefitsDesktop";
import { NotFoundDesktop } from "./NotFoundDesktop";

import { PartnerMobile } from "./PartnerMobile";
import { PartnerDesktop } from "./PartnerDesktop";
import { CentralDesktop } from "./CentralDesktop";
import { QuestionsDesktop } from "./QuestionsDesktop";
import { OffersDesktop } from "./OfferDesktop";
import { OffersMobile } from "./OfferMobile";
import { Footer } from "./Footer";

import { actionGetLandingPageByDNS } from "redux/actions/LandingPage/Data";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

export const PrivateTemplate = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const history = useHistory();

  const {
    selected: { payload: lp, error },
  } = useSelector(({ landingPage }) => landingPage);

  const [offerType, setOfferType] = useState("");
  const _COMPANY_ = 'company';
  const _FORYOU_ = 'foryou';

  const {
    selected: {payload: { ofertas }}
  } = useSelector(({landingPage}) => landingPage);

  useEffect(() => {
    dispatch(actionSetRegistration({}));
    dispatch(actionGetLandingPageByDNS(parsed["?lp"] || "testediogo"));
    setTabByOfferType();
  }, []);

  useEffect(() => {
    const selected = history.location.hash
    if (selected && selected.length > 0) {
      const elem = document.querySelector(selected)
      elem && elem.scrollIntoView()
    }
  }, [lp])


  const setTabByOfferType = () => {
    if(handleEnableButtons("PRAVOCE") && handleEnableButtons("PARASUAEMPRESA"))
      setOfferType(_FORYOU_);
    else if(!handleEnableButtons("PRAVOCE") && handleEnableButtons("PARASUAEMPRESA"))
      setOfferType(_COMPANY_);
    else if(handleEnableButtons("PRAVOCE") && !handleEnableButtons("PARASUAEMPRESA"))
      setOfferType(_FORYOU_);
  }

  const handleEnableButtons = (type) => {
    if(ofertas) {
      var itemOffer = ofertas[0];
      var plans = itemOffer.planos;
      return (plans.filter(x => x.tipoCliente == type).length > 0);
    }
  }

  const handleClickOfferType = (type) => {
    setOfferType(type);
  };

  return (
    <>
      {(!error) ? (
        <S.Container>
          <HeaderDesktop />
          <HeaderMobile />
          <MainPanelMobile 
            
            onClickOfferType={handleClickOfferType} 
          />
          <MainPanelDesktop 
            onClickOfferType={handleClickOfferType} 
            forYouEnable={handleEnableButtons}
            forCompanyEnable={handleEnableButtons}
            offerType={offerType}
          />
          <BenefitsMobile />
          <BenefitsDesktop />
          <OffersDesktop
            offerType={offerType}
            onClickOfferType={handleClickOfferType}
          />
          <OffersMobile
            offerType={offerType}
            onClickOfferType={handleClickOfferType}
          />
          <PartnerMobile />
          <PartnerDesktop />
          <QuestionsDesktop />
          <CentralDesktop />
          <Footer error={error} />
        </S.Container>
      ) : (
        <S.Container>
          <HeaderNotFoundDesktop />
          <HeaderNotFoundMobile />
          <NotFoundDesktop />
          <Footer error={error} />
        </S.Container>
      )}
    </>
  );
};

PrivateTemplate.propTypes = {
  children: PropTypes.node.isRequired,
};
