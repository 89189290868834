import { SHOW_SPINNER } from 'redux/constants/Wrapper/Spinner'

const initialState = {
  payload: false
}

const Spinner = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_SPINNER:
      return {
        ...state,
        payload: action.payload
      }
    default:
      return state
  }
}

export default Spinner
