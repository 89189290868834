import styled, {css} from 'styled-components';
import breakpoints from "../../../../utils/mediaQueries/media";

export const Container = styled.section`
  background: ${({theme}) => theme.colors.white};
  padding-top: 100px;
  padding-bottom: 40px;

  @media (max-width: ${breakpoints.lg}) {
    display: none;
  }
`

export const OffersContainer = styled.div`
      align-items:center;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      width: 1300px;
`

export const OffersTitle = styled.div`
  color: #004077;
  font-size: 35px;
  font-weight: 700;
  margin: 8px 0;
`

export const OffersSubtitle = styled.div`
  color: #4D5761;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 32px;
`

export const Offers = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
`

const variants = {
    default: () => {},
    defaultSvg: () => css`
        width: 25%;
    `,
    popular: (props) => css`
      border: 1px solid ${props.corPrimaria};
    `,
    active: (props) => css`
      border-bottom: 6px solid ${props.corSecundaria};

      & > p {
        color: ${props.corSecundaria};
      }
      
      & > svg {
           width: 25%;
      }
      
      & > svg > path {
           fill: ${props.corSecundaria} !important;
      }
    `
}


export const OfferItem = styled.div`
  background: #FFFFFF;
  border: 1px solid ${(props) => props.corSecundaria};
  box-sizing: border-box;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  width: 290px;
  height: 722px;
  padding: 32px;
  margin: 0 17px;
  ${(props) => variants[props.variant]}
`

export const OfferItemEmp = styled.div`
  background: #FFFFFF;
  border: 1px solid ${(props) => props.corSecundaria};
  box-sizing: border-box;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  width: 290px;
  height: fit-content;
  padding: 32px;
  margin: 0 17px;
  ${(props) => variants[props.variant]}
`




export const OfferTabs = styled.div`
  width: 50%;
  display: flex;
  margin-bottom: 56px;
  justify-content: space-evenly;
`

export const TabItem = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 6px solid #A3AAB1;
  padding-bottom: 15px;
  margin-right: 30px;
    width: fit-content;
    
  & > p {
    color: #A3AAB1;
    margin-left: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 22px;
  }

  ${(props) => variants[props.variant](props)}
`

export const PlanPopular = styled.div`
  background: ${(props) => props.bgColor};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  border-radius: 6px;
  position: relative;
  width: 123px;
  height: 31px;
  top: -46px;
  color: ${({theme}) => theme.colors.white};
  margin-bottom: -30px;
`

export const PlanTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #2A3037;
  margin-bottom: 16px;
`
export const PlanPrice = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  color: ${(props) => props.defaultColor};

  & > small {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #6A6A6A;
  }
`
export const PlanTag = styled.div`
  border: 1px solid #4D5761;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  margin: 24px 0;
`
export const PlanText = styled.div`
  border-top: 1px solid #4D5761;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  color: #2A3037;
  padding: 24px 0;
`
export const PlanCTA = styled.div`
  display: grid;
  justify-content: center;

  & button {
    margin-bottom: 15px;
  }
`
export const PlanList = styled.div`
  & li {
    margin-top: 12px;
    display: flex;

    & img {
      margin-right: 8px;
    }
  }
`
