import * as Yup from "yup";
import ValidationRules from "../validations/validationsSchemas";

export const addressSchema = Yup.object().shape({
  zipCode: ValidationRules.Required(),
  street: ValidationRules.Required(),
  neighborhood: ValidationRules.Required(),
  city: ValidationRules.Required(),
  state: ValidationRules.Required(),
  number: ValidationRules.Required(),
  adhesionAgreement: ValidationRules.CheckNoNumber(),  
});
