import React from "react";
import PropTypes from "prop-types";
import { Image, Typography } from "components/common";
import ArrowDown from "assets/icons/arrow-down.svg";
import * as S from "./styled";

export const MiniPurchaseInfo = ({ onOpen }) => {
  return (
    <S.PurchaseInfo>
      <S.PurchaseContent>
        <S.PlanBox>
          <div>
            <Typography tag="h5" variant="secondary">
              Sem mensalidade fixa
            </Typography>
            <Typography tag="text" variant="secondary">
              Pague por recarga
            </Typography>
          </div>
          <S.BtnChangeModal onClick={onOpen}>
            <Image src={ArrowDown} alt="open" />
          </S.BtnChangeModal>
        </S.PlanBox>
        <S.Total>
          <S.Badge size="md" variant="secondary">
            <span>
              Total
              <strong style={{ marginLeft: 12 }}>{` R$ 40,00`}</strong>
            </span>
          </S.Badge>
        </S.Total>
      </S.PurchaseContent>
    </S.PurchaseInfo>
  );
};

MiniPurchaseInfo.propTypes = {
  onOpen: PropTypes.func.isRequired,
};
