import moment from "moment";

moment.locale("pt-BR");
export const isDate = (value) => moment(value, "DD/MM/YYYY", true).isValid();

export const isPastDate = (value) =>
  moment(value, "DD/MM/YYYY").isSameOrBefore(moment(), "day");

export const minDateForBirthDate = (value) =>
  moment(new Date()).diff(moment(value, "DD/MM/YYYY"), "years") >= 18;

export const isDateTime = (value) => {
  var formats = [
    "DD/MM/YYYY LT",
    "DD/MM/YYYY h:mm:ss A",
    "DD/MM/YYYY HH:mm:ss",
    "DD/MM/YYYY HH:mm",
  ];
  return moment(value, formats, true).isValid();
};
export const isAfterMore2minutesDateTime = (value) => {
  const twoMinutesAgo = moment().add(2, "minutes");
  const myDate = moment(value, "DD/MM/YYYY H:mm:ss A");
  return myDate.isAfter(moment(twoMinutesAgo));
};

export const isAfterMore1minutesDateTime = (startDate, endDate) => {
  const endDateFormat = moment(endDate, "DD/MM/YYYY H:mm:ss A");
  const onHourAgo = moment(startDate, "DD/MM/YYYY H:mm:ss A").add(1, "hour");
  return endDateFormat.isSameOrAfter(moment(onHourAgo));
};
