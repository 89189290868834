import styled from 'styled-components'
import { pxToRem } from 'utils/scales/converter'

export const Container = styled.div`
  animation-delay: ${(props) =>
    props.alwaysVisible ? 99999999 : props.secondTimeOutToClose}s;
  animation-direction: forwards;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-name: cssAnimation;
  animation-timing-function: ease-in;
  bottom: -35px;
  display: block;
  left: ${pxToRem(10)};
  padding: 0;
  position: fixed;
  width: ${pxToRem(500)};
  z-index: 10000;

  label,
  .snackbar {
    align-items: center;
    animation-delay: 1s;
    animation-direction: forwards;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-name: snackbar-show;
    animation-timing-function: ease-in-out;

    @keyframes snackbar-show {
      0% {
        bottom: ${pxToRem(10)};
      }

      90%,
      95% {
        bottom: ${pxToRem(35)};
      }

      92.5%,
      100% {
        bottom: ${pxToRem(60)};
      }
    }
  }
`
