import styled from "styled-components";
import breakpoints from "utils/mediaQueries/media";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.offwhite.light};
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const widthBar = {
  1: "18px",
  2: "88px",
  3: "178px",
  4: "100%",
};

export const ProgressBar = styled.div`
  height: 4px;
  background-color: #ff6338;
  width: ${({ step }) => widthBar[step]};

  @media (min-width: ${breakpoints.sm}) {
    display: none;
  }
`;

export const Content = styled.main`
  width: 100%;
  flex: 1;
  background-color: #ffffff;

  @media (min-width: ${breakpoints.sm}) {
    background-color: #f7f2ed;
  }
`;

export const PurchaseInfoWrapper = styled.div`
  background-color: #ffffff;
  height: ${({ open }) => (open ? "103vh" : "161px")};
  width: 100%;
  position: fixed;
  bottom: 0;
  transition: height 0.15s ease-in;
  @media (min-width: ${breakpoints.sm}) {
    display: none;
  }
`;
