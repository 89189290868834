import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DropIn from "braintree-web-drop-in-react";
import { Form, Row, Col, Button, Image } from "components/common";
import {
  actionGetWidgetToken,
  actionCreatePP,
} from "redux/actions/Payment/Data";
import LeftArrow from "assets/icons/left-arrow.svg";
import { clearMask } from "utils/format/string";

export const Payment = ({ onBack, onNext }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    selected: { payload: landingPageData }
  } = useSelector(({ landingPage }) => landingPage);

  const {
    data: { payload: paymentData },
  } = useSelector(({ payment }) => payment);

  const {
    data: { payload: formData },
  } = useSelector(({ registration }) => registration);

  const {
    data: { payload: dataAddress },
  } = useSelector(({ address }) => address);

  const [braintreeInstance, setBraintreeInstance] = useState("");
  const [token, setToken] = useState("");

  useEffect(() => dispatch(actionGetWidgetToken()), []);

  useEffect(() => {
    if (!isEmpty(paymentData)) {
      setToken(paymentData.token);
    }
  }, [paymentData]);

  const handleSubmit = async () => {
    const brainTreeRequest = await braintreeInstance.requestPaymentMethod();    
    const birthdateSplitted = formData.birthdate.split("/");
    const criterios = formData.planSelected.criterios;
    const request = {
      origemCliente: landingPageData.id,
      cpf: clearMask(formData.cpf),
      nome: formData.name,
      sobrenome: formData.lastName,
      email: formData.email,
      celular: clearMask(formData.phoneNumber),
      cep: clearMask(dataAddress.cep),
      endereco: dataAddress.logradouro,
      numeroEndereco: formData.number,
      complemento: formData.complement,
      bairro: dataAddress.bairro,
      cidade: dataAddress.cidade,
      estado: dataAddress.estado,
      ibgeCode: dataAddress.ibge,
      quantidade: 1,
      dataDeNascimento: `${birthdateSplitted[2]}-${birthdateSplitted[1]}-${birthdateSplitted[0]}`,
      genero: null,
      codigoProduto: formData.planSelected.tituloPlano,
      codigoCampanha: (Array.isArray(criterios) && criterios.length) ? (Array.isArray(criterios[0].valor) && criterios[0].valor.length) ? criterios[0].valor[0] : null : null,
      rg: null,
      nomeMae: formData.mothersName,
      politicamenteExposta: formData.adhesionAgreement,
      placaVeiculo: null,
      valorEmCentavos: formData.planSelected.valorAdesaoEmCentavos,
      emailsPromocionais: null,
      dadosPagamentoPci: {
        dadosDoDispositivo: brainTreeRequest.deviceData,
        nomeNoCartao: brainTreeRequest.details.cardholderName,
        dadosDoCartaoDeCredito: brainTreeRequest.nonce
      }
    };
    console.log(request);
    dispatch(actionCreatePP(request, dispatch, onNext));
  };

  const formik = useFormik({
    initialValues: {},
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      {token && (
        <div style={{ marginBottom: 100 }}>
          <DropIn
            
            options={{
              authorization: token,
              dataCollector: { kount: true },
              locale:'pt_BR', 
              card: {
                cardholderName: {required: true}
              }
            }}
            onInstance={(instance) => {
              setBraintreeInstance(instance);
            }}
          />
        </div>
      )}
      <Row gutterWidth={40} align="center">
        <Col xs={12} sm={6}>
          <Button
            className="btn-back"
            id="btn_forgot_password"
            variant="link-primary"
            onClick={() => onBack()}
          >
            <Image
              src={LeftArrow}
              alt="left-arrow"
              style={{ marginRight: 10 }}
            />
            Etapa anterior
          </Button>
        </Col>
        <Col xs={12} sm={6}>
          <Button id="btn_save_account" type="submit" fullWidth={true}>
            Próximo Passo
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
