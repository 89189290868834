import styled, { css } from 'styled-components'
import breakpoints from 'utils/mediaQueries/media'

const wrapperVariants = {
  primary: () => css`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: ${({ theme }) => theme.spacing.sm};
    position: relative;
    width: 100%;

    &.error {
      input:focus {
        border-color: ${({ theme }) => theme.colors.critical.dark};
      }

      input,
      input:focus,
      .icon-input {
        border-color: ${({ theme }) => theme.colors.critical.dark};
      }
    }
    input {
      &::placeholder {
        color: ${({ theme }) => theme.colors.gray};
        font-size: 15px;
      }
    }
  `,

  secondary: () => css`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;

    &.error {
      input:focus {
        border-color: ${({ theme }) => theme.colors.critical.dark};
      }

      input,
      input:focus,
      .icon-input {
        border-color: ${({ theme }) => theme.colors.critical.dark};
      }
    }
    input {
      &::placeholder {
        color: ${({ theme }) => theme.colors.tertiary.dark};
        font-size: ${({ theme }) => theme.fontSize.base};
      }
    }
  `
}

export const InputGroupWrapper = styled.div`
  input:focus,
  .icon-input {
    border-color: ${({ theme }) => theme.colors.primary.base};
  }
  ${(props) => wrapperVariants[props.variant]}
`

export const Label = styled.label`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  display: inline-block;
  font-size: 15px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  left: 0;
  line-height: ${({ theme }) => theme.lineHeight.base};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  padding: 0;
  pointer-events: none;
  transition: all 0.2s ease;
`

const inputVariants = {
  primary: () => css`
    .floating {
      appearance: none;
      background-color: ${({ theme }) => theme.colors.offwhite.light};
      border: 1px solid ${({ theme }) => theme.colors.text.tertiary};
      color: ${({ theme }) => theme.colors.text};
      ${(props) => radius[props.radius]}
      font-size: 15px;
      line-height: ${({ theme }) => theme.lineHeight.base};
      min-height: 55px;
      padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.base}`};
      transition: ${({ theme }) => theme.transition.base};
      width: 100%;

      &:disabled {
        background-color: ${({ theme }) => theme.colors.offwhite.base};
        border-color: ${({ theme }) => theme.colors.gray};
        cursor: default;
      }

      &:disabled + label {
        background-color: ${({ theme }) => theme.colors.offwhite.base};
        background-image: linear-gradient(
          to top,
          ${({ theme }) => theme.colors.offwhite.base} 0%,
          ${({ theme }) => theme.colors.offwhite.base} 30%,
          ${({ theme }) => theme.colors.white} 50%,
          ${({ theme }) => theme.colors.white} 100%
        );
        color: ${({ theme }) => theme.colors.text};
      }

      &:disabled[value=''] + label {
        background-image: none;
      }

      &:focus,
      &:active {
        left: 2px;
        outline: none;
      }
    }
  `,

  secondary: () => css`
    .floating {
      appearance: none;
      background-color: ${({ theme }) => theme.colors.white};
      border: none;
      border-bottom: 1px solid ${({ theme }) => theme.colors.tertiary.base};
      color: ${({ theme }) => theme.colors.secondary.base};
      ${(props) => radius[props.radius]}
      font-size: ${({ theme }) => theme.fontSize.base};
      line-height: ${({ theme }) => theme.lineHeight.base};
      min-height: 46px;
      transition: ${({ theme }) => theme.transition.base};
      width: 100%;

      &:disabled {
        background-color: ${({ onlyView, theme }) =>
          onlyView ? theme.colors.white.base : theme.colors.tertiary.light};
        color: ${({ onlyView, theme }) =>
          onlyView ? theme.colors.secondary.base : theme.colors.tertiary.dark};
        border-color: ${({ theme }) => theme.colors.tertiary.base};
        cursor: default;
      }

      &:disabled + label {
        background-color: ${({ theme }) => theme.colors.tertiary.light};
        color: ${({ theme }) => theme.colors.tertiary.dark};
        width: 100%;
      }

      &:disabled[value=''] + label {
        background-image: none;
      }

      &:focus,
      &:active {
        left: 2px;
        outline: none;
      }
    }
  `
}

const radius = {
  sm: () => css`
    border-radius: ${({ theme }) => theme.radius.md};
  `,
  md: () => css`
    border-radius: 10px;
  `
}

export const InputContainer = styled.div`
  ${(props) => inputVariants[props.variant]}

  display: flex;
  flex-direction: column;
  order: 1;
  position: relative;
`

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.critical.dark};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-style: italic;
  margin-top: 2px;
  min-height: ${({ theme }) => theme.spacing.sm};
  order: 2;
`

export const ShowPassword = styled.div`
  align-items: center;
  bottom: 0;
  color: ${({ theme }) => theme.colors.gray};
  cursor: pointer;
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.xl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  height: 46px;
  order: 2;
  position: absolute;
  right: 10px;
  text-transform: uppercase;
`

export const InputGroupIconWrapper = styled.div`
  align-items: center;
  display: flex;

  @media (max-width: ${breakpoints.md}) {
    width: 90%;
  }
`
export const ShowIconError = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  height: 46px;
  order: 2;
  position: absolute;
  right: -30px;
`

export const ShowIconClose = styled.div`
  -ms-flex-align: center;
  -ms-flex-order: 2;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -webkit-order: 2;
  align-items: center;
  bottom: 6px;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  order: 2;
  position: absolute;
  right: -15px;
`
