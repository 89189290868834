import React from 'react'
import {Button, Image, Typography} from 'components/common'
import WhiteLogo from 'assets/images/white-logo.svg'
import * as S from './styled'
import {isEmpty} from 'lodash';
import {useSelector} from "react-redux";

export const HeaderDesktop = () => {
    const {
        selected: {payload: lp}
    } = useSelector(({landingPage}) => landingPage);
    return (
        <>
            {!isEmpty(lp) &&
                <S.HeaderBase
                    primaryColor={lp.painelPrincipal.corPrimaria}
                    secondaryColor={lp.painelPrincipal.corSecundaria}
                >
                    <S.Header>
                        <S.HeaderConnect>
                            <Image src={WhiteLogo} alt="white-logo" width={163}/>
                        </S.HeaderConnect>
                        <S.HeaderPartner>
                            <Image src={lp.arquivo.logoParceiro} alt="partner-logo" width={64}/>
                        </S.HeaderPartner>
                        <S.HeaderMenu>
                            <Typography tag="h2" align="center" style={{color: 'white'}}><a href="#d_homepage">Início</a></Typography>
                            <Typography tag="h2" align="center" style={{color: 'white'}}><a href="#d_benefits">Benefícios</a></Typography>
                            <Typography tag="h2" align="center" style={{color: 'white'}}><a href="#d_partners">Sobre a parceria</a></Typography>
                            <Typography tag="h2" align="center" style={{color: 'white'}}><a href="#questions">Perguntas frequentes</a></Typography>
                        </S.HeaderMenu>
                        <Button
                            href="#d_offers"
                            style={{backgroundColor: lp.painelPrincipal.corSecundaria}}
                        >Peça o seu!</Button>
                    </S.Header>

                </S.HeaderBase>
            }
        </>
    )
}
