import { applyMiddleware, createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import {
  spinnerMiddleware,
  snackbarErrorMiddleware,
  snackbarSuccessMiddleware,
  snackbarHandleExceptionMiddleware
} from 'redux/Middleware'
import createRootReducer from 'redux/reducers'
import storage from 'redux-persist/lib/storage'


const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = createRootReducer();
const presistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(
  presistedReducer,
  composeWithDevTools(
    applyMiddleware(
      thunkMiddleware,
      spinnerMiddleware,
      snackbarErrorMiddleware,
      snackbarSuccessMiddleware,
      snackbarHandleExceptionMiddleware
    )
  )
);

const persistor = persistStore(store);
export { persistor, store };