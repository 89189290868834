import * as Yup from "yup";
import ValidationRules from "../validations/validationsSchemas";

export const personalDataSchema = Yup.object().shape({
  name: ValidationRules.Required(),
  lastName: ValidationRules.Required(),
  email: ValidationRules.Email(),
  cpf: ValidationRules.Cpf(),
  income: ValidationRules.Required(),
  phoneNumber: ValidationRules.CellPhone(),
  birthdate: ValidationRules.BirthDateDateNotFuture(),
  mothersName: ValidationRules.Required(),
});
