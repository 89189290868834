import React from 'react';
import {Image, Typography} from 'components/common';
import * as S from './styled';
import Minus from 'assets/icons/minus.svg';
import Plus from 'assets/icons/plus.svg';
import {isEmpty} from 'lodash';
import {useSelector} from "react-redux";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';


export const QuestionsDesktop = () => {
    const {
        selected: {payload: lp}
    } = useSelector(({landingPage}) => landingPage);
    return (
        <>
            {!isEmpty(lp) &&
            <S.Container
                id="questions"
            >
                <S.QuestionContainer>
                    <S.QuestionTitle>
                        <h1 align="center" >
                            Perguntas frequentes
                        </h1>
                    </S.QuestionTitle>
                    <S.QuestionSubtitle>
                        <Typography tag="text" align="center">
                            É um fato conhecido de todos que um leitor se distrairá com o conteúdo de texto legível de
                            uma página quando estiver examinando sua diagramação.
                        </Typography>
                    </S.QuestionSubtitle>
                </S.QuestionContainer>
                <S.AccordionContainer>
                    <Accordion>
                        {lp.faq.itens.map((item) => {
                            return (<AccordionItem key={item.pergunta}>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {
                                                ({expanded}) => (expanded ? <Image alt="minus" src={Minus}/> :
                                                    <Image alt="plus" src={Plus}/>)
                                            }
                                        </AccordionItemState>
                                        <h3 style={{color: lp.faq.corPrimaria}}>
                                            {item.pergunta}
                                        </h3>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <Typography tag="text" align="center">
                                        {item.resposta}
                                    </Typography>
                                </AccordionItemPanel>
                            </AccordionItem>)
                        })}
                    </Accordion>
                </S.AccordionContainer>
            </S.Container>
            }
        </>
    )
}
