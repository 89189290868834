import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  PurchaseInfo,
  MiniPurchaseInfo,
  Spinner,
  Snackbar,
} from "components/common";
import Header from "./Header";
import Footer from "./Footer";
import * as S from "./styled";

export const RegisterTemplate = ({ children }) => {
  const { step } = useParams();

  const {
    wrapper: {
      spinner: { payload: showSpinner },
    },
    dialogs: {
      snackbar: { payload: snackbar },
    },
  } = useSelector((state) => state);

  const [open, setOpen] = useState(false);

  const stepNumber = () => {
    switch (step) {
      case "personal-data":
        return 1;
      case "address":
        return 2;
      case "payment":
        return 3;
      case "confirmation":
        return 4;
      default:
        return 0;
    }
  };

  const handleChangeOpen = () => setOpen(!open);

  return (
    <Spinner active={showSpinner}>
      <Snackbar
        show={snackbar.show}
        type={snackbar.type}
        alwaysVisible={snackbar.alwaysVisible}
        secondTimeOutToClose={snackbar.secondTimeOutToClose}
        message={snackbar.message}
      />
      <S.Container>
        <Header />
        <S.ProgressBar step={stepNumber()} />
        <S.Content>{children}</S.Content>
        <Footer step={step} />
        {step !== "confirmation" && (
          <S.PurchaseInfoWrapper open={open}>
            {(!open && <MiniPurchaseInfo onOpen={handleChangeOpen} />) || (
              <PurchaseInfo onOpen={handleChangeOpen} />
            )}
          </S.PurchaseInfoWrapper>
        )}
      </S.Container>
    </Spinner>
  );
};
