import React from 'react'
import * as S from './styled'
import {Image, Typography} from 'components/common';
import WhiteLogo from 'assets/images/white-logo.svg'
import PartnerLogo from 'assets/images/partner-logo.svg'
import {useSelector} from "react-redux";
import {isEmpty} from 'lodash';

export const Footer = ({error}) => {
    const {
        selected: {payload: lp}
    } = useSelector(({landingPage}) => landingPage);
  return (

    <S.Footer error={error}>
        {!isEmpty(lp) &&
        <S.GridContainer>
            <S.ImageContainer>
                <S.FooterConnect>
                    <Image alt="Logo" src={WhiteLogo}/>
                </S.FooterConnect>
                <S.FooterPartner>
                    <Image alt="Partner" src={PartnerLogo}/>
                </S.FooterPartner>
            </S.ImageContainer>
            <Typography tag="text" align="center" variant="secondary">
                Oferta válida para clientes pessoa física que adquirirem o adesivo através dessa página entre 01/01/2021
                e 30/06/2021.
                Taxa de adesão de R$ 30,00 (trinta reais) convertida integralmente em créditos após ativação, exceto
                para substituições de adesivos, em até 5 dias úteis.
                Campanha válida por tempo limitado e sujeita a alteração sem aviso prévio. Consulte regras e validade do
                benefício no <a style={{color: '#FF6338'}} href={lp.arquivo.termoAdesao}>regulamento da campanha</a>
            </Typography>
        </S.GridContainer>
        }
    </S.Footer>

  )
}
