import styled, { css } from "styled-components";
import breakpoints from "utils/mediaQueries/media";

export const PurchaseInfoWrapper = styled.div`
  background-color: #ffffff;
  height: ${({ open }) => (open ? "100vh" : "161px")};
  width: 100%;
  position: fixed;
  bottom: 0;
  transition: height 0.15s ease-in;

  @media (min-width: ${breakpoints.sm}) {
    display: none;
  }
`;

export const PurchaseInfo = styled.div`
  padding: 16px;
  background-color: #f7f2ed;
`;

export const PurchaseContent = styled.div`
  background-color: #fbfafa;
  height: 100%;
  border: 0.5px solid #bec4cc;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

  @media (min-width: ${breakpoints.sm}) {
    height: 55px;
  }
`;

export const PlanBox = styled.div`
  border-bottom: 0.5px solid #bec4cc;
  padding: 9px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BtnChangeModal = styled.div`
  cursor: pointer;

  @media (min-width: ${breakpoints.sm}) {
    display: none;
  }
`;

export const Total = styled.div`
  display: flex;
  padding: 9px 15px;
  flex-direction: row-reverse;
`;

const badgeSize = {
  md: "42px",
  sm: "29px",
};

const variants = {
  primary: () => css`
    background-color: #ff6338;
  `,

  secondary: () => css`
    background-color: #4d5761;
  `,
};

export const Badge = styled.div`
  min-width: 100px;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "")};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 5px 30px;
  font-family: Lato;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
  ${({ variant }) => variants[variant]}
`;

export const PurchaseInfoFullHeight = styled.div`
  padding: 32px 16px;

  @media (min-width: ${breakpoints.sm}) {
    width: 100%;
  }
`;

export const PurchaseInfoHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const PlanBoxSimple = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 9px 15px;
`;

export const RowInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: ${({ borderNone }) =>
    borderNone ? "none" : "1px solid #d8dce7"};
  padding: 16px 0;
  flex-wrap: wrap;
`;
