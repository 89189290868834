import styled from "styled-components";

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  margin-bottom: ${({ theme, spacing }) =>
    spacing === "" ? 0 : `${theme.spacing[spacing]}`};

  span {
    margin-left: 9px;
    font-family: Lato;
    font-weight: 500;
    font-size: 12px;
    color: #4d5761;
  }

  strong {
    color: #ff6338;
  }
`;
