import styled from "styled-components";
import { pxToRem } from "utils/scales/converter";

const renderBackGroundColor = (type) => {
  switch (type) {
    case "error":
      return "#FF0303";
    case "success":
      return "#47bb41";
    case "warning":
      return "#ffa000";
    default:
      return "#1976d2";
  }
};

export const StyledSnackbar = styled.div.attrs({
  className: "snackbar",
})`
  background-color: ${(props) => renderBackGroundColor(props.type)};
  border-radius: ${pxToRem(4)};
  bottom: ${pxToRem(-70)};
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.6);
  color: #ffffff;
  display: flex;
  font-family: "Helvetica", "Helvetica Neue", "Roboto", "Arial", sans-serif;
  justify-content: space-between;
  left: 0;
  min-height: ${pxToRem(60)};
  padding: ${pxToRem(40)} ${pxToRem(30)};
  position: relative;
  transition: bottom 0.5s ease-in-out;
  z-index: 10;

  p {
    width: 100%;
    color: #ffffff;
  }

  svg:first-child {
    margin-right: ${pxToRem(10)};
  }
`;
