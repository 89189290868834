import React from 'react';
import {Button, Typography} from 'components/common';
import * as S from './styled';
import {isEmpty} from 'lodash';
import {useSelector} from "react-redux";

export const CentralDesktop = () => {
    const {
        selected: {payload: lp}
    } = useSelector(({landingPage}) => landingPage);

    return (
        <>
            {!isEmpty(lp) &&
            <S.Container>
                <S.CentralContainer
                    bgColor={lp.centralAtendimento.corSecundaria}
                >
                    <S.CentralTags
                        bgColor={lp.centralAtendimento.corPrimaria}
                    >
                        <Typography tag="text" style={{color: "white"}}>
                            {lp.centralAtendimento.tagAtendimento}
                        </Typography>
                    </S.CentralTags>
                    <S.CentralTitle>
                        <h1 align="center">
                            {lp.centralAtendimento.textoAtendimento}
                        </h1>
                    </S.CentralTitle>
                    <S.CentralButton>
                        <Button
                            href={lp.centralAtendimento.urlAtendimento}
                            style={{backgroundColor: lp.centralAtendimento.corPrimaria}}
                        >Iniciar Conversa</Button>
                    </S.CentralButton>
                </S.CentralContainer>
            </S.Container>
            }
        </>
    )
}
