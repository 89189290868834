import styled from 'styled-components'
import breakpoints from "../../../../utils/mediaQueries/media";

export const Container = styled.section`
  align-items: center;
  background: ${(props) => props.bgColor};
  border: solid 1px #EDEDED;
  display: none;
  height: fit-content;
  justify-content: flex-start;
  padding: 40px 17px;
  width: 100vw;

  @media (max-width: ${breakpoints.lg}) {
    display: block;
  }

`

export const PartnerList = styled.div`
    width: 100%;
    padding: 0 15px;
`

export const ListItem = styled.div`
    display: flex;
    width: 100%;
    & p {
        margin-bottom: 15px;
        margin-left: 11px;
        font-size: 16px;
    }
`

export const PartnerTitle = styled.div`
   color: #004077;
   margin: 8px 0;
`

export const PartnerSubtitle = styled.div`
    color: #4D5761;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 32px;
`

export const PartnerImage = styled.div`
    margin-top: 18px;
    width: 100%;
    
    & img {
        border-radius: 8px;
        width: 100%;
    }
`

export const PartnerButton = styled.div`
    display: flex;
    margin-top: 32px;
    justify-content: center;
    width: 100%;
    
    & button {
       background: #004077;
    }
`
