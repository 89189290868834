import React from "react";
import PropTypes from "prop-types";
import * as S from "./styled";

const Image = ({ src, alt, className, spacing, ...rest }) => (
  <S.ImageWrapper
    spacing={spacing}
    className={className}
    src={src}
    alt={alt}
    {...rest}
  />
);

Image.propTypes = {
  src: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.node,
    PropTypes.elementType,
  ]).isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  spacing: PropTypes.oneOf([
    "",
    "min",
    "xs",
    "smx",
    "sm",
    "sml",
    "base",
    "mdx",
    "md",
    "mdl",
    "lgx",
    "lg",
    "lgl",
    "max",
  ]),
};

Image.defaultProps = {
  className: null,
  spacing: "",
};

export { Image };
