import styled, { css } from "styled-components";
import breakpoints from "utils/mediaQueries/media";

export const Container = styled.div`
  margin-top: 20px;
  margin-bottom: 50px;
  width: 100%;

  .btn-back {
    display: none;
  }

  @media (min-width: ${breakpoints.sm}) {
    .btn-back {
      display: flex;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    padding: 0 20px;
  }

  @media (min-width: ${breakpoints.lg}) {
    padding: 0 320px;
  }
`;

export const ContainerForm = styled.div`
  margin-top: 20px;

  .icon-back {
    display: block;
  }

  @media (min-width: ${breakpoints.sm}) {
    background-color: ${({ step }) =>
      step === "confirmation" ? "#f7f2ed" : "#ffffff"};
    padding: 22px 20px 40px;
    border: ${({ step }) =>
      step === "confirmation" ? "none" : "1px solid #ededed"};
    box-sizing: border-box;
    border-radius: 4px;

    .icon-back {
      display: none; 
    }
  }
`;

export const ContainerPurchaseInfo = styled.div`
  display: none;

  @media (min-width: ${breakpoints.sm}) {
    margin-top: 20px;
    padding: 0 4px 0px 4px;
    display: flex;
    width: 100%;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #ededed;
    box-sizing: border-box;
    border-radius: 4px;
  }
`;

const variants = {
  selected: () => css`
    color: #293b8a;
  `,
  complete: () => css`
    color: #298f7a;
  `,
};

export const ItemStep = styled.span`
  display: flex;
  align-items: center;
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #4d5761;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  ${(props) => variants[props.variant]}

  span {
    margin-left: 10px;
  }
`;

export const StepWrapper = styled.div`
  display: none;
  justify-content:center !important;     

  @media (min-width: ${breakpoints.sm}) {
    display: flex;
    margin-left: 15px;
    align-items: center;
    height: 25px;     
  }
`;

export const Separator = styled.div`
  width: 24px;
  height: 1px;
  background: #bec4cc;
  margin-right: 24px;
  margin-left: 38px;
  margin-top: 5px;
`;
