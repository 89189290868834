import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Image,
  Typography,
  Row,
  Col,
  Grid,
  PurchaseInfo,
  Spinner, 
  Snackbar
} from "components/common";
import ArrowLeftPrimary from "assets/icons/arrow-left-primary.svg";
import CompleteIcon from "assets/icons/complete.svg";
import { actionCreateLP } from "redux/actions/Payment/Data";
import { CompanyData } from "../CompanyData";
import { Contact } from "../Contact";
import { Address } from "../Address";
import { BilletPayment } from "../BilletPayment";
import { CompanyConfirmation } from "../CompanyConfirmation";
import { clearMask, currencyToDouble } from "utils/format/string";

import * as S from "./styled";

export const CompanyPaymentFlowPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { step } = useParams();

  const {
    wrapper: {
      spinner: { payload: showSpinner },
    },
    dialogs: {
      snackbar: { payload: snackbar },
    },
  } = useSelector((state) => state);

  const {
    selected: { payload: lp },
  } = useSelector(({ landingPage }) => landingPage);

  const {
    data: { payload: formData },
  } = useSelector(({ registration }) => registration);

  const {
    data: { payload: dataAddress },
  } = useSelector(({ address }) => address);

  const stageCompanyData = (menu) => {
    switch (menu) {
      case "company-data":
        return "selected";
      default:
        return "default";
    }
  };

  const stageContact = (menu) => {
    switch (menu) {
      case "company-data":
        return "complete";
      case "contact":
        return "selected";
      default:
        return "default";
    }
  };

  const stageAddress = (menu) => {
    switch (menu) {
      case "company-data":
        return "complete";
      case "contact":
        return "complete";
      case "address":
        return "selected";
      default:
        return "default";
    }
  };

  const stagePayment = (menu) => {
    switch (menu) {
      case "company-data":
        return "complete";
      case "contact":
        return "complete";
      case "address":
        return "complete";
      case "payment":
        return "complete";
      default:
        return "default";
    }
  };

  const variant = (menu) => {
    switch (step) {
      case "company-data":
        return stageCompanyData(menu);
      case "contact":
        return stageContact(menu);
      case "address":
        return stageAddress(menu);
      case "payment":
        return stagePayment(menu);
      default:
        return "default";
    }
  };

  const stepIconPersonalData = () => {
    switch (step) {
      case "company-data":
        return "1.";
      default:
        return <Image src={CompleteIcon} alt="ok" />;
    }
  };

  const stepIconContact = () => {
    switch (step) {
      case "company-data":
      case "contact":
        return "2.";
      default:
        return <Image src={CompleteIcon} alt="ok" />;
    }
  };

  const stepIconAddress = () => {
    switch (step) {
      case "company-data":
      case "contact":
      case "address":
        return "3.";
      default:
        return <Image src={CompleteIcon} alt="ok" />;
    }
  };

  const stepIconPayment = () => {
    switch (step) {
      case "company-data":
      case "contact":
      case "address":
        return "4.";
      default:
        return <Image src={CompleteIcon} alt="ok" />;
    }
  };

  const handleSubmit = () => {
    const dateOfIncorporationSplitted = formData.dateOfIncorporation.split("/");
    const representativeSinceSplitted = formData.representativeSince.split("/");
    const cellPhone = clearMask(formData.contactCompanyCellphone);
    const phoneNumber = clearMask(formData.contactCompanyPhone);

    const request = {
      origemCliente: lp.id,
      cnpj: clearMask(formData.cnpj),
      razaoSocial: formData.socialName,
      quantidadeAdesivos: 1,
      recargaAutomatica: null,
      naturezaJuridica: formData.legalNature,
      faturamentoMedioMensal: currencyToDouble(formData.invoicing),
      dataConstituicao: `${dateOfIncorporationSplitted[2]}-${dateOfIncorporationSplitted[1]}-${dateOfIncorporationSplitted[0]}`,
      nomeRepresentante: formData.name + ' ' + formData.lastName,
      cpf: clearMask(formData.cpf),
      email: formData.email,
      cargo: formData.post,
      dataRepresentante: `${representativeSinceSplitted[2]}-${representativeSinceSplitted[1]}-${representativeSinceSplitted[0]}`,
      expostaPublicamente: formData.adhesionAgreement,
      receberOfertas: formData.receiveOffers,
      compartilhaDados: formData.shareData,
      termoAceite: true,
      compartilhaDadosItau: formData.shareData,
      enderecos: [
        {
          cep: clearMask(dataAddress.cep),
          logradouro: dataAddress.logradouro,
          numero: formData.number,
          complemento: formData.complement,
          bairro: dataAddress.bairro,
          uf: dataAddress.estado,
          cidade: dataAddress.cidade,
          codigoIbge: dataAddress.ibge
        }
      ],
      contatos: [
        {
          principal: 1,
          nome: formData.contactName,
          email: formData.email,
          dddTelefone: phoneNumber ? phoneNumber.substring(0, 2) : null,
          telefone: phoneNumber ? phoneNumber.substring(2, 10) : null,
          dddCelular: cellPhone ? cellPhone.substring(0, 2) : null,
          celular: cellPhone ? cellPhone.substring(2, 11) : null
        }
      ]
    };
    console.log(request)

    var onNext = () => history.push("/company-payment-flow/company-confirmation");
    dispatch(actionCreateLP(request, dispatch, onNext));
  };

  const renderForm = () => {
    switch (step) {
      case "company-data":
        return <CompanyData />;
      case "contact":
        return <Contact onBack={() => history.push("/company-payment-flow/company-data")} />;
      case "address":
        return (
          <Address
            onBack={() => history.push("/company-payment-flow/contact")}
            onNext={() => history.push("/company-payment-flow/payment")}
          />
        );
      case "payment":
        return <BilletPayment
          onBack={() => history.push("/company-payment-flow/address")}
          onNext={handleSubmit} />;
      case "company-confirmation":
        return <CompanyConfirmation />;
      default:
        break;
    }
  };

  const pageTitle = {
    "company-data": "Dados da empresa",
    contact: "Contato",
    address: "Endereço da empresa",
    payment: "Pagamento",
  };

  const subtitle = {
    "company-data": "* Campo de preenchimento obrigatório",
    contact: "* Campo de preenchimento obrigatório",
    address: "* Campo de preenchimento obrigatório",
  };

  const btnBack = {
    "company-data": () => history.push("/"),
    contact: () => history.push("/company-payment-flow/company-data"),
    address: () => history.push("/company-payment-flow/contact"),
    payment: () => history.push("/company-payment-flow/address"),
  };
  return (
    <Spinner active={showSpinner}>
      <Snackbar
        show={snackbar.show}
        type={snackbar.type}
        alwaysVisible={snackbar.alwaysVisible}
        secondTimeOutToClose={snackbar.secondTimeOutToClose}
        message={snackbar.message}
      />
      <S.Container>
        <S.StepWrapper>
          <S.ItemStep variant={variant("company-data")}>
            {stepIconPersonalData()} <span>Dados Pessoais</span>
          </S.ItemStep>
          <S.Separator />
          <S.ItemStep variant={variant("contact")}>
            {stepIconContact()} <span>Contato</span>
          </S.ItemStep>
          <S.Separator />
          <S.ItemStep variant={variant("address")}>
            {stepIconAddress()} <span>Endereço da empresa</span>
          </S.ItemStep>
          <S.Separator />
          <S.ItemStep variant={variant("payment")}>
            {stepIconPayment()} <span>Pagamento</span>
          </S.ItemStep>
        </S.StepWrapper>
        <Grid>
          <Row style={{ margin: 0, padding: 0 }}>
            <Col xs={12} lg={8}>
              <S.ContainerForm step={step}>
                {step !== "confirmation" && (
                  <Image
                    className="icon-back"
                    onClick={btnBack[step]}
                    src={ArrowLeftPrimary}
                    alt="back"
                    style={{ marginBottom: 14, cursor: "pointer" }}
                  />
                )}
                <Typography tag="h5" spacing="xs">
                  {pageTitle[step]}
                </Typography>
                <Typography tag="disclaimer" spacing="mdx">
                  {subtitle[step]}
                </Typography>
                {renderForm()}
              </S.ContainerForm>
            </Col>
            {step !== "confirmation" && (
              <Col xs={12} lg={4}>
                <S.ContainerPurchaseInfo>
                  <PurchaseInfo onOpen={() => { }} />
                </S.ContainerPurchaseInfo>
              </Col>
            )}
          </Row>
        </Grid>
      </S.Container>
    </Spinner>
  );
};
