import styled, { css } from "styled-components";

import breakpoints from "utils/mediaQueries/media";

const variants = {
  primary: () => css`
    background-color: ${({ theme }) => theme.colors.primary.base};

    &:not([disabled]):hover {
      &::before {
        opacity: 1;
      }
    }

    &[disabled] {
      background-color: ${({ theme }) => theme.colors.text.tertiary};
      color: ${({ theme }) => theme.colors.tertiary.base};
      cursor: default;
    }
  `,

  secondary: () => css`
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.primary.base};
    color: ${({ theme }) => theme.colors.primary.base};

    &:not([disabled]):hover {
      &::before {
        opacity: 1;
      }
    }
  `,

  tertiary: () => css`
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    color: ${({ theme }) => theme.colors.text.secondary};
    font-size: ${({ theme }) => theme.fontSize.md};
    font-weight: 500;

    height: 48px;
    min-height: 48px;
    min-width: 208px;
  `,

  icon: () => css`
    background-color: ${({ theme }) => theme.colors.primary.base};
    border-radius: 50%;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05), 0px 5px 7px rgba(0, 0, 0, 0.05);
    min-height: 24px;
    min-width: 24px;
    padding: 0;
  `,

  "link-primary": () => css`
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.primary.base};
    font-size: 13px;
    font-style: normal;
    justify-content: start;
    margin: 0;
    min-height: 0;
    padding: 0;

    &[disabled] {
      background-color: ${({ theme }) => theme.colors.gray};
      border-color: ${({ theme }) => theme.colors.gray};
      color: ${({ theme }) => theme.colors.white};
      cursor: default;
    }

    &:not([disabled]):hover {
      &::before {
        background-color: ${({ theme }) => theme.colors.primary.dark};
        opacity: 0.1;
      }
    }
  `,

  "link-secondary": () => css`
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.secondary.base};
    font-size: 14px;
    margin: 0;
    min-height: 0;
    padding: 0;
    text-decoration: underline;

    &[disabled] {
      background-color: ${({ theme }) => theme.colors.gray};
      border-color: ${({ theme }) => theme.colors.gray};
      color: ${({ theme }) => theme.colors.white};
      cursor: default;
    }

    &:not([disabled]):hover {
      &::before {
        background-color: ${({ theme }) => theme.colors.primary.dark};
        opacity: 0.1;
      }
    }
  `,

  blue: () => css`
    background: #004077;
    border-radius: 90px;
    color: ${({ theme }) => theme.colors.white};
  `,

  borderBlue: () => css`
    background: none;
    border: 1px solid #004077;
    border-radius: 90px;
    color: #004077;
  `,
};

const radius = {
  md: () => css`
    border-radius: 90px;
  `,
};

const size = {
  sm: () => css`
    font-size: ${({ theme }) => theme.fontSize.sm};
    min-height: 2.4rem;
    min-width: 100px;
    padding: 0 ${({ theme }) => theme.spacing.sm};
  `,
  md: () => css`
    font-size: ${({ theme }) => theme.fontSize.lg};
    min-height: 4.8rem;
  `,
};

export const ButtonWrapper = styled.button`
  align-content: center;
  align-items: center;

  border: 1.5px solid transparent;
  color: ${({ theme }) => theme.colors.tertiary.light};
  cursor: pointer;
  display: inline-flex;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  justify-content: center;
  line-height: ${({ theme }) => theme.lineHeight.base};
  margin-bottom: ${({ theme, spacing }) =>
    spacing === "" ? 0 : `${theme.spacing[spacing]}`};
  min-width: 200px;
  overflow: hidden;
  padding-left: ${({ theme }) => theme.spacing.md};
  padding-right: ${({ theme }) => theme.spacing.md};
  position: relative;
  text-decoration: none;
  transition: ${({ theme }) => theme.transition.base};
  user-select: none;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "")};
  ${(props) => size[props.size]}
  ${(props) => variants[props.variant]}
  ${(props) => radius[props.radius]}

  &::before {
    ${(props) => radius[props.radius]}
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: ${({ theme }) => theme.transition.base};
    z-index: ${({ theme }) => theme.layers.base};
  }

  z-index: 0;
  &.flex {
    flex: 1;
    margin-right: ${({ theme }) => theme.spacing.sm};

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: ${breakpoints.sm}) {
      margin-bottom: ${({ theme }) => theme.spacing.sm};
      margin-right: 0;
      width: 100%;
    }
  }

  .children {
    display: inherit;
    font-size: inherit;
    position: relative;
    z-index: ${({ theme }) => theme.layers.base};
  }

  .icon {
    font-size: ${({ theme }) => theme.fontSize.xl};
    z-index: ${({ theme }) => theme.layers.base};
  }
`;

const iconPosition = {
  left: () => css`
    padding-right: ${({ theme }) => theme.spacing.xs};
  `,
  right: () => css`
    order: 2;
    padding-left: ${({ theme }) => theme.spacing.xs};
  `,
};

export const IconWrapper = styled.i`
  line-height: 0;
  padding: 0;

  ${(props) => !props.onlyIcon && iconPosition[props.iconPosition]}

  svg {
    font-size: 100%;
  }
`;

export const ButtonGroup = styled.div`
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: ${({ theme }) => theme.spacing.sm} 0;

  > * {
    margin-right: ${({ theme }) => theme.spacing.sm};
    order: 1;

    &:first-child {
      order: 0;
    }

    &:last-child {
      margin-right: 0;
      order: 2;
    }
  }
`;
