import React, { useState, useEffect } from "react";
import { Image, Typography, Button, Col, Row } from "components/common";
import HeaderLogo from "assets/images/conectcar-logo.svg";
import ShareIcon from "assets/icons/share.svg";
import DoneCircle from "assets/icons/done-circle.svg";
import BilletIcon from "assets/icons/billet.svg";
import LeftArrow from "assets/icons/left-arrow.svg";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import formatDate from "utils/format/date"
import * as S from "./styled";

export const BilletPayment = ({onBack, onNext}) => {

  const [planSelected, setPlanSelected] = useState({});

  const {
    data: { payload: orderData },
  } = useSelector(({ order }) => order);

  const {
    selected: { payload: lp },
  } = useSelector(({ landingPage }) => landingPage);

  const {
    data: { payload },
  } = useSelector(({ registration }) => registration);

  useEffect(() => {
    if (!isEmpty(lp)) {
      const newPlanSelected = lp.ofertas[0].planos[payload.indexSelectedOrder];
      setPlanSelected(newPlanSelected);
    }
  }, [lp])

  return (
    <>
      <S.Container>
        <S.Card>
          <S.CardRow>
            <Image src={BilletIcon} alt="billet" />
            <span>Boleto bancário</span>
          </S.CardRow>
          <S.CardContent>
            <Typography tag="h3" variant="primary" spacing="smx">
              Pagamento por boleto
            </Typography>
            <S.BoxValue> {planSelected?.valorAdesaoFormatado} à vista</S.BoxValue>
            <S.BoxTutorial style={{ marginTop: 20 }}>
              <S.ItemTutorial>
                1. Acesse seu Internet Banking ou <br />
                <span> app de pagamentos</span>
              </S.ItemTutorial>
              <S.ItemTutorial>2. Escolha pagar boleto</S.ItemTutorial>
              <S.ItemTutorial>
                3. Abra o boleto clicando no botão "Gerar boleto"
              </S.ItemTutorial>
              {/* <S.ItemTutorial>
                4. Copie o código de barras
              </S.ItemTutorial> */}
            </S.BoxTutorial>
            {/* <S.Action>
              <Button id="btn_copy_billet" variant="secondary" size="sm">
                Ver Boleto
              </Button>
            </S.Action> */}

            <Typography tag="h3" variant="primary" spacing="smx">
              Importante
            </Typography>
            <S.BoxTutorial>
              <S.ItemTutorial>
                Se o boleto não for pago até a data do vencimento, o pedido será
                automaticamente cancelado;
              </S.ItemTutorial>
              <S.ItemTutorial style={{ marginTop: 20 }}>
                O prazo de entrega dos pedidos pagos com boleto bancário começa
                a contar três dias após o pagamento do boleto, tempo necessário
                para que a instituição bancária confirme o pagamento.
              </S.ItemTutorial>
            </S.BoxTutorial>
          </S.CardContent>
        </S.Card>
        </S.Container>
      <Row align="center">
        <Col xs={12} sm={6}>
          <Button
            className="btn-back"
            id="btn_forgot_password"
            variant="link-primary"
            onClick={() => onBack()}
          >
            <Image
              src={LeftArrow}
              alt="left-arrow"
              style={{ marginRight: 10 }}
            />
            Etapa anterior
          </Button>
        </Col>
        <Col xs={12} sm={6}>
          <Button id="btn_save_account" type="submit" fullWidth={true} onClick={() => onNext()}>
            Finalizar pedido
          </Button>
        </Col>
      </Row>
    </>
  );
};
