import React, { Suspense } from 'react'
import { routes as Routes } from 'routes'
import { Router, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import GlobalStyles from 'assets/styles/globalStyles'
import { themeDefault } from 'config/initializer/themes'
import { history } from 'utils'

function App() {
  return (
    <ThemeProvider theme={themeDefault}>
      <GlobalStyles />
      <Router history={history}>
        <Suspense fallback={false}>
          <Switch>
            <Routes />
          </Switch>
        </Suspense>
      </Router>
    </ThemeProvider>
  )
}

export default App
