import axios from "axios";
import EnvironmentVariables from "../../environment-variables";

const api = axios.create({
  baseURL: EnvironmentVariables.API_URL,
  headers: {
    "Ocp-Apim-Subscription-Key": EnvironmentVariables.API_SUBSCRIPTION_KEY,
    "Content-Length": 2433,
  },
});

api.interceptors.request.use(async (config) => {
  return config;
});

export default api;
