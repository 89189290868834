import styled from 'styled-components'
import { pxToRem } from 'utils/scales/converter'

export const SnackbarLabel = styled.label.attrs({
  htmlFor: 'close'
})`
  border: 0;
  bottom: ${pxToRem(-70)};
  color: #ffffff;
  cursor: pointer;
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 100;
`
