import { UseChangeRoute } from 'hooks'

const authenticationRoutes = {
  signIn: {
    redirect() {
      UseChangeRoute(this.path)
    }
  }
}

const loggedInRoutes = {
  mainPage: {
    redirect() {
      UseChangeRoute(this.path)
    }
  }
}

const myaccountRoutes = {
  contract: {
    redirect() {
      UseChangeRoute(this.path)
    }
  }
}

export const allRoutes = [
  {
    id: 'main',
    private: true
  },
  {
    id: 'first-access"',
    private: false
  }
]
const listRoutes = Object.assign(
  {},
  authenticationRoutes,
  loggedInRoutes,
  myaccountRoutes
)

export default listRoutes
