import React, { useEffect, useState } from 'react';
import {isEmpty} from 'lodash';
import {useSelector} from "react-redux";
import {Image, Typography, Row, Col} from 'components/common';
import ParaVc from 'assets/icons/main-paravc.svg';
import ParaEmp from 'assets/icons/main-paraempresa.svg';
import * as S from './styled';


export const MainPanelDesktop = ({ onClickOfferType, forYouEnable, forCompanyEnable, offerType }) => {
    const {
        selected: {payload: lp}, 
    } = useSelector(({landingPage}) => landingPage);

    useEffect(() => {
        onClickOfferType(offerType);
    }, []);

    return (
        <>
            {!isEmpty(lp) &&
            <S.Container
                id="d_homepage"
                primaryColor={lp.painelPrincipal.corPrimaria}
                secondaryColor={lp.painelPrincipal.corSecundaria}
            >
                <S.MainContainer>
                    <Row style={{width: '100%'}}>
                        <Col>
                            <S.MainTags
                                bgColor={lp.painelPrincipal.corSecundaria}
                            >
                                <Typography tag="text"  style={{color: "white"}}>
                                    {lp.painelPrincipal.infoTag}
                                </Typography>
                            </S.MainTags>
                            <S.MainTitle>
                                <h1>
                                    {lp.painelPrincipal.tituloPrincipal}
                                </h1>
                            </S.MainTitle>
                            <S.MainSubtitle>
                                {lp.painelPrincipal.textoPrincipal}
                            </S.MainSubtitle>
                            <S.CTAContainer>
                                <Row>
                                    {forYouEnable("PRAVOCE") && (
                                        <Col sm={6}>
                                            <S.MainIcon
                                                marginLeft='1'
                                                marginTop='0'
                                                href="#d_offers"
                                                onClick={() => onClickOfferType('foryou')}
                                            >
                                                <Image alt="Para voce" src={ParaVc}/>
                                                <Typography tag="text" variant="secondary">
                                                    Para você
                                                </Typography>
                                            </S.MainIcon>
                                        </Col>)
                                    }
                                    {forCompanyEnable("PARASUAEMPRESA") && (
                                        <Col sm={6}>
                                            <S.MainIcon
                                                marginLeft='2.5'
                                                marginTop='0'
                                                href="#d_offers"
                                                onClick={() => onClickOfferType('company')}
                                            >
                                                <Image alt="Para voce" src={ParaEmp}/>
                                                <Typography tag="text" variant="secondary">
                                                    Para sua empresa
                                                </Typography>
                                            </S.MainIcon>
                                        </Col>
                                    )}
                                </Row>
                            </S.CTAContainer>
                        </Col>
                        <Col>
                            <S.MainImage>
                                <Image alt='Main Photo' src={lp.arquivo.linkImagemPrincipal}/>
                            </S.MainImage>
                        </Col>
                    </Row>
                </S.MainContainer>
            </S.Container>
            }
        </>
    )
}
