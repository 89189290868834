import React from 'react';
import {Image, Typography, Button} from 'components/common';
import CheckedIcon from 'assets/icons/checked.svg';
import * as S from './styled';
import {isEmpty} from 'lodash';
import {useSelector} from "react-redux";

export const BenefitsMobile = () => {
    const {
        selected: {payload: lp}
    } = useSelector(({landingPage}) => landingPage);
    return (
        <>
            {!isEmpty(lp) &&
            <S.Container
                id="benefits"
                corPrimaria={lp.painelBeneficio.corPrimaria}
            >
                <S.BenefitsTitle>
                    <h1 style={{color: lp.painelBeneficio.corSecundaria}}>
                        {lp.painelBeneficio.tituloBeneficio}
                    </h1>
                </S.BenefitsTitle>
                <S.BenefitsSubtitle>
                    {lp.painelBeneficio.textoBeneficio}
                </S.BenefitsSubtitle>
                <S.BenefitsList>
                    {lp.painelBeneficio.listaBeneficios.map((bn) => {
                        return <>
                            <S.ListItem>
                                <Image alt="Checked" src={CheckedIcon}/>
                                <Typography>
                                    {bn.nome}
                                </Typography>
                            </S.ListItem>
                        </>
                    })}
                </S.BenefitsList>
                <S.BenefitsImage>
                    <Image alt='Main Photo' src={lp.arquivo.linkImagemBeneficio}/>
                </S.BenefitsImage>
                <S.BenefitsButton>
                    <Button
                        style={{backgroundColor: lp.painelBeneficio.corSecundaria}}
                        href="#offers"
                    >Saber mais</Button>
                </S.BenefitsButton>
            </S.Container>
            }
        </>
    )
}
