import styled, { css } from 'styled-components';
import breakpoints from "../../../../utils/mediaQueries/media";

export const Container = styled.section`
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  display: none;
  justify-content:center;
  padding-top: 50px;
  width:100vw;
  
  @media(max-width: ${breakpoints.lg}) {
    display: block;
  }
`

export const OffersContainer = styled.div`
    display:grid;
    justify-items: center;
    width: 100vw;
    padding-bottom: 50px;
    overflow-x: hidden;
`

export const OffersTitle = styled.div`
   color: #004077;
   margin: 8px 0;
`

export const OffersSubtitle = styled.div`
    color: #4D5761;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 32px;
    padding: 0 15px;
`

export const Offers = styled.div`
    display: flex;
    width: max-content;
    padding-bottom: 10px;
`

const variants = {
    default: () => css``,
    popular: (props) => css`
      border: 1px solid ${props.corPrimaria};
    `,
    active: (props) => css`
      border-bottom: 6px solid ${props.corSecundaria};

      & > p {
        color: ${props.corSecundaria};
      }

      & > svg > path {
           fill: ${props.corSecundaria} !important;
      }
    `
}


export const OfferItem = styled.div`
    background: #FFFFFF;
    border: 1px solid ${(props) => props.corSecundaria};
    box-sizing: border-box;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 18px;
    width: 290px;
    height: 722px;
    padding: 32px;
    margin-right: 35px;
    ${(props) => variants[props.variant](props)}

`

export const TabsContainer = styled.div`
    width: 100vw;
    overflow-x: scroll;
`

export const OfferTabs = styled.div`
    display: flex;
    justify-content: space-between;
    width: max-content;
    padding: 0 15px;
`

export const TabItem = styled.div`
    display: block;
    align-items: center;
    border-bottom: 6px solid #A3AAB1;
    padding-bottom: 15px;
    margin-right: 20px;
    
    & > p {
        color: #A3AAB1;
        font-style: normal;
        font-weight: bold;
        font-size: 1.5em;
    }
    
    & > svg {
        height: fit-content;
        width: 48px;
    }
    
    ${(props) => variants[props.variant]}
`

export const PlanContainer = styled.div`
    width: 100vw;
    overflow-x: scroll;
    padding: 15px;
    margin-top:15px;
`

export const PlanPopular = styled.div`
  background: ${(props) => props.bgColor};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    border-radius: 6px;
    position: relative;
    width: 123px;
    height: 31px;
    top: -46px;
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: -30px;
`

export const PlanTitle = styled.div`
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #2A3037;
    margin-bottom: 16px;
`
export const PlanPrice = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    color: #004077;
    
    & > small {
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #6A6A6A;
    }
`
export const PlanTag = styled.div`
    border: 1px solid #4D5761;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    margin: 24px 0;
`
export const PlanText = styled.div`
    border-top: 1px solid #4D5761;
    font-weight: normal;
    font-size: 18px;
    line-height: 140%;
    color: #2A3037;
    padding: 24px 0;
`
export const PlanCTA = styled.div`
    display: grid;
    justify-content: center;
    
    & button {
        margin-bottom: 15px;
    }
`
export const PlanList = styled.div`
    &  li {
        margin-top: 12px;
        display: flex;
        
        & img {
            margin-right: 8px;
        }
    }
`
