import styled from "styled-components";

export const ImageWrapper = styled.img`
  border: 0;
  display: block;
  height: auto;
  max-width: 100%;
  margin-bottom: ${({ theme, spacing }) =>
    spacing === "" ? 0 : `${theme.spacing[spacing]}`};
`;
