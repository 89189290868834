import {
  FETCH_GET_ADDRESS_BY_POSTAL_CODE_FAILURE,
  FETCH_GET_ADDRESS_BY_POSTAL_CODE_REQUEST,
  FETCH_GET_ADDRESS_BY_POSTAL_CODE_SUCCESS,
  UPDATE_ADDRESS_BY_POSTAL_CODE,
  SET_ADRESS_DATA
} from "redux/constants/Address/Data";

const initialState = {
  loading: false,
  error: false,
  payload: {},
};

const Data = (state = initialState, action) => {
  switch (action.type) {
    case SET_ADRESS_DATA:      
      return { payload: action.payload }
    case FETCH_GET_ADDRESS_BY_POSTAL_CODE_REQUEST:
    case UPDATE_ADDRESS_BY_POSTAL_CODE:
      return {
        payload: {},
      };
    case FETCH_GET_ADDRESS_BY_POSTAL_CODE_SUCCESS:
      return {
        payload: action.payload.dados,
      };
    case FETCH_GET_ADDRESS_BY_POSTAL_CODE_FAILURE:
      return {
        payload: {},
      };
    default:
      return state;
  }
};

export default Data;
