import React from 'react'
import {Image} from 'components/common'
import WhiteLogo from 'assets/images/white-logo.svg'
import PartnerLogo from 'assets/images/partner-logo.svg'
import * as S from './styled'

export const HeaderNotFoundMobile = () => {
    return (
        <>
          <S.Header primaryColor={'#FF6338'} secondaryColor={'#3f12ff'} >
            <S.HeaderConnect>
                <Image src={WhiteLogo} alt="white-logo"/>
            </S.HeaderConnect>
            <S.HeaderPartner>
                <Image src={PartnerLogo} alt="partner-logo"/>
            </S.HeaderPartner>
            </S.Header>
        </>
    )
}
