import React from 'react';
import {Image, Typography, Button} from 'components/common';
import * as S from './styled';
import {isEmpty} from 'lodash';
import {useSelector} from "react-redux";

export const PartnerMobile = () => {
    const {
        selected: {payload: lp}
    } = useSelector(({landingPage}) => landingPage);
    return (
        <>
            {!isEmpty(lp) &&
            <S.Container
                id="partners"
                bgColor={lp.sobreParceria.corSecundaria}
            >
                <S.PartnerTitle>
                    <h1 style={{color: lp.sobreParceria.corPrimaria}}>
                        {lp.sobreParceria.tituloSobreParceria}
                    </h1>
                </S.PartnerTitle>
                <S.PartnerSubtitle>
                    <Typography tag="text">
                        {lp.sobreParceria.textoSobreParceria}
                    </Typography>
                </S.PartnerSubtitle>
                <S.PartnerImage>
                    <Image alt='Main Photo' src={lp.arquivo.linkImagemSobreParceria}/>
                </S.PartnerImage>
                <S.PartnerButton>
                    <Button
                        style={{backgroundColor: lp.sobreParceria.corPrimaria}}
                        href="#offers"
                    >Peça o seu!</Button>
                </S.PartnerButton>
            </S.Container>
            }
        </>
    )
}
