import styled from 'styled-components'
import breakpoints from "../../../../utils/mediaQueries/media";

export const Container = styled.section`
  background: ${(props) => props.corPrimaria};
  display: flex;
  padding-top: 190px;
  padding-bottom: 90px;
  @media (max-width: ${breakpoints.lg}) {
    display: none;
  }
`

export const BenefitsContainer = styled.div`
      align-items:center;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      padding: 0 50px;
      width: 1280px;
`

export const BenefitsList = styled.div`
    width: 100%;
    padding: 0 15px;
    margin-top: 45px;
`

export const ListItem = styled.div`
    display: flex;
    width: 100%;
    & p {
        margin-bottom: 15px;
        margin-left: 11px;
        font-size: 16px;
    }
`

export const BenefitsTitle = styled.div`
   color: #004077;
   font-size: 35px;
   font-weight: 700;
   margin: 8px 0;
`

export const BenefitsSubtitle = styled.div`
    color: #4D5761;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 32px;
`

export const BenefitsImage = styled.div`    
    & img {
        border-radius: 8px;
        width: fit-content;
        height: 500px;
    }
`

export const BenefitsButton = styled.div`
    margin-top: 18px;
    width: 100%;
    
    & button {
       background: #004077;
    }
`

export const BenefitsInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top:10%;
  justify-self: center;
`
