import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'

const Button = ({
  children,
  className,
  icon: Icon,
  variant,
  onlyIcon,
  iconPosition,
  type,
  radius,
  href,
  flex,
  size,
  fullWidth,
  spacing,
  ...rest
}) => (
  <S.ButtonWrapper
    className={`${className} ${flex ? 'flex' : ''}`}
    spacing={spacing}
    variant={variant}
    fullWidth={fullWidth}
    radius={radius}
    size={size}
    type={type}
    {...rest}
    {...(href && {
      href,
      as: 'a',
      role: 'link'
    })}
  >
    {Icon && (
      <S.IconWrapper
        className="icon"
        onlyIcon={onlyIcon}
        iconPosition={iconPosition}
      >
        <Icon />
      </S.IconWrapper>
    )}

    <span className="children">{children}</span>
  </S.ButtonWrapper>
)

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.node,
    PropTypes.elementType
  ]),
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'link-primary',
    'link-secondary',
    'icon',
    'tertiary',
    'blue',
    'borderBlue'
  ]),
  href: PropTypes.string,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  onlyIcon: PropTypes.bool,
  flex: PropTypes.bool,
  radius: PropTypes.oneOf(['sm', 'md', 'lg']),
  size: PropTypes.oneOf(['xm', 'sm', 'md', 'lg']),
  type: PropTypes.string,
  fullWidth: PropTypes.bool,
  spacing: PropTypes.oneOf([
    '',
    'min',
    'xs',
    'smx',
    'sm',
    'sml',
    'base',
    'mdx',
    'md',
    'mdl',
    'lgx',
    'lg',
    'lgl',
    'max'
  ])
}

Button.defaultProps = {
  className: '',
  variant: 'primary',
  children: '',
  iconPosition: 'left',
  href: '',
  radius: 'md',
  size: 'md',
  type: 'button',
  icon: '',
  onlyIcon: false,
  flex: false,
  fullWidth: false,
  spacing: ''
}

Button.Group = S.ButtonGroup

export { Button }
