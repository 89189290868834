import styled from "styled-components";
import breakpoints from "utils/mediaQueries/media";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const Card = styled.div`
  border: 1px solid #ff6338;
  padding: 30px 20px;
  border-radius: 8px;
  background-color: #ffffff;
  width: 100%;
  margin-bottom: 32px;

  @media (min-width: ${breakpoints.sm}) {
    width: 455px;
  }
`;

export const CardRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  font-family: Lato;
  font-weight: bold;
  font-size: 15px;
  color: #2a3037;
  border-bottom: 1px solid #d8dce7;
  padding-bottom: 17px;
  span {
    margin-left: 7px;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  border-bottom: 1px solid #f7f7f7;
  padding: 0 20px;
`;

export const Action = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 52px;

  button {
    min-height: 40px;
    width: 167px;
  }

  @media (min-width: ${breakpoints.sm}) {
    button {
      min-height: 54px;
      width: 201px;
    }
  }
`;

export const BoxValue = styled.div`
  border: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Lato;
  font-weight: bold;
  font-size: 24px;
  color: #293b8a;
  padding: 16px 0;
  margin-top: 11px;
`;

export const BoxTutorial = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

export const ItemTutorial = styled.p`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #707070;

  span {
    margin-left: 15px;
  }
`;
