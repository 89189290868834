import {    
    CREATE_PAYMENT_PP_SUCCESS, 
    CREATE_PAYMENT_LP_SUCCESS
  } from "redux/constants/Payment/Data";
  
  const initialState = {
    loading: false,
    error: false,
    payload: {},
  };
  
  const Data = (state = initialState, action) => {       
    switch (action.type) {
      case CREATE_PAYMENT_PP_SUCCESS:
      case CREATE_PAYMENT_LP_SUCCESS:        
        return {
          payload: action.payload.dados,
        };      
      default:
        return state;
    }
  };
  
  export default Data;