import React from 'react'

import PropTypes from 'prop-types'

import * as S from './styled'

export const Grid = ({
  children,
  className,
  size,
  direction,
  margin,
  ...rest
}) => (
  <S.ContainerWrapper
    size={size}
    direction={direction}
    className={className}
    margin={margin}
    {...rest}
  >
    {children}
  </S.ContainerWrapper>
)

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'fluid']),
  margin: PropTypes.string,
  direction: PropTypes.oneOf([
    'row',
    'row-reverse',
    'column',
    'column-reverse',
    'inherit',
    'initial',
    'unset'
  ])
}

Grid.defaultProps = {
  className: null,
  size: 'fluid',
  direction: 'column',
  margin: '0'
}
