import styled from 'styled-components'
import breakpoints from "../../../../utils/mediaQueries/media";

export const MobileContainer = styled.section`
  background-color: ${(props) => props.primaryColor };
  display: none;
  padding: 0 17px 20px 17px;
  width: 100vw;
  
  @media(max-width: ${breakpoints.lg}) {
    display: block;
  }
  
`

export const MobileCTAContainer = styled.div`
    width: 100%;
    padding: 0 15px;
`


export const MobileMainTags = styled.div`
  background-color: ${(props) => props.bgColor};
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.white};
  height: 31px;
  margin-top: 30px;
  padding: 6px;
  width: fit-content;
`

export const MobileMainIcon = styled.a`
  align-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.primary.base};
  display: grid;
  height: 132px;
  margin: 3% 3%;
  text-decoration: none;
  & img {
    justify-self: center;
  }
`

export const MobileMainTitle = styled.div`
   color: ${({ theme }) => theme.colors.white};
   margin: 8px 0;
`

export const MobileMainSubtitle = styled.div`
    color: ${({ theme }) => theme.colors.white};
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 32px;
`

export const MobileMainImage = styled.div`
    margin-top: 18px;
    width: 100%;
    
    & img {
        border-radius: 8px;
        width: 100%;
    }
`
