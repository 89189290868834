import styled from 'styled-components'

const hidden = `overflow: hidden;
overflowY: hidden;
overflowX: hidden;`

export const Container = styled.div.attrs(({ active }) => ({
  id: 'spinner',
  'data-spinner': active
}))`
  ${(props) => (props.active ? hidden : '')}
  height: 100%;
  position: relative;
  display: flex;
  width: 100%;
`
