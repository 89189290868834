import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  margin-bottom: ${({ theme, spacing }) =>
    spacing === "" ? "17px" : `${theme.spacing[spacing]}`};
`;

export const Label = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  display: inline-block;
  font-size: 15px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  left: 0;
  line-height: ${({ theme }) => theme.lineHeight.base};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  padding: 0;
  pointer-events: none;
  transition: all 0.2s ease;
`;
export const ShowIconError = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  height: 46px;
  order: 2;
  position: absolute;
  right: -30px;
`;

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.critical.dark};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-style: italic;
  margin-top: 2px;
  min-height: ${({ theme }) => theme.spacing.sm};
  order: 2;
`;

export const ShowIconClose = styled.div`
  -ms-flex-align: center;
  -ms-flex-order: 2;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -webkit-order: 2;
  align-items: center;
  bottom: 6px;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  order: 2;
  position: absolute;
  right: -15px;
`;
