import React from 'react';
import {Image, Typography, Row, Col, Button} from 'components/common';
import * as S from './styled';
import CheckedIcon from "../../../../assets/icons/checked.svg";
import {isEmpty} from 'lodash';
import {useSelector} from "react-redux";

export const BenefitsDesktop = () => {
    const {
        selected: {payload: lp}
    } = useSelector(({landingPage}) => landingPage);
    return (
        <>
            {!isEmpty(lp) &&
            <S.Container
                id="d_benefits"
                corPrimaria={lp.painelBeneficio.corPrimaria}
            >
                <S.BenefitsContainer>
                    <Row>
                        <Col sm={6}>
                            <S.BenefitsImage>
                                <Image alt='Main Photo' src={lp.arquivo.linkImagemBeneficio}/>
                            </S.BenefitsImage>
                        </Col>
                        <Col sm={6}>
                            <S.BenefitsInfo>
                                <S.BenefitsTitle>
                                    <h1 style={{color: lp.painelBeneficio.corSecundaria}}>
                                        {lp.painelBeneficio.tituloBeneficio}
                                    </h1>
                                </S.BenefitsTitle>
                                <S.BenefitsSubtitle>
                                    {lp.painelBeneficio.textoBeneficio}
                                </S.BenefitsSubtitle>
                                <S.BenefitsList>
                                    <Row>
                                        {lp.painelBeneficio.listaBeneficios.map((it) => {
                                            return <>
                                                <Col sm={6}>
                                                    <S.ListItem>
                                                        <Image alt="Checked" src={CheckedIcon}/>
                                                        <Typography>
                                                            {it.nome}
                                                        </Typography>
                                                    </S.ListItem>
                                                </Col>
                                            </>
                                        })}
                                    </Row>
                                </S.BenefitsList>
                                <S.BenefitsButton>
                                    <Button
                                        style={{backgroundColor: lp.painelBeneficio.corSecundaria}}
                                        href="#d_offers"
                                    >Saber mais</Button>
                                </S.BenefitsButton>
                            </S.BenefitsInfo>
                        </Col>
                    </Row>
                </S.BenefitsContainer>
            </S.Container>
            }
        </>
    )
}
