import styled from 'styled-components'
import breakpoints from "../../../../utils/mediaQueries/media";

export const Container = styled.section`
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  display: grid;
  justify-content:center;
  padding-top: 100px;
  padding-bottom: 40px;
  width:100vw;
  
  @media(max-width: ${breakpoints.lg}) {
    h1 {
        font-size: 0.8em;
    }
    
    h3 {
        font-size: 1em; 
    }
  }
`

export const QuestionContainer = styled.div`
      align-items:center;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      width: 1300px;
      
    @media(max-width: ${breakpoints.lg}) {
        width: 100vw;
    }
`

export const AccordionContainer = styled.div`
    width: 80%;
    justify-self: center;
    
    & .accordion__heading {
        margin-bottom: 15px; 
    }
    
    & .accordion__heading {
        border: 1px solid #C4C4C4;
        border-radius: 5px;
        padding: 21px 27px;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 34px;
        color: #004077;
    }
    
    & .accordion__panel {
        font-size: 16px;
        line-height: 140%;
        padding: 25px 51px;
        
    }
    
    & .accordion__button {
        display: flex;
        & img {
            margin-right: 6px;
        }
    }
`

export const QuestionTitle = styled.div`
   color: #004077;
   font-size: 35px;
   font-weight: 700;
   margin: 8px 0;
`

export const QuestionSubtitle = styled.div`
    color: #4D5761;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 32px;
    width: 50%;
`
