const initialState = {
  payload: {}
}

const PartnerData = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_REGISTRATION_DATA_2':
      return {
        payload: action.payload
      }
    default:
      return state
  }
}

export default PartnerData
