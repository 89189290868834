import styled from "styled-components";
import breakpoints from "utils/mediaQueries/media";

export const Container = styled.footer`
  width: 100%;
  background-color: #2a3037;
  height: ${({ step }) => (step === "confirmation" ? "230px" : "380px")};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0;

  @media (min-width: ${breakpoints.sm}) {
    flex-direction: row;
    height: 84px;
    justify-content: space-between;
  }

  @media (min-width: ${breakpoints.md}) {
    padding: 0 20px;
  }

  @media (min-width: ${breakpoints.lg}) {
    padding: 0 320px;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 20px;
    height: 22px;
    margin-top: 18px;
    margin-bottom: 12px;
  }
  h4 {
    margin-bottom: 4px;
  }

  @media (min-width: ${breakpoints.sm}) {
    flex-direction: row;

    h4 {
      margin: 0;
      margin-left: 4px;
      margin-bottom: 0;
    }

    img {
      margin-right: 16px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`;
