import styled from 'styled-components';
import breakpoints from "../../../../utils/mediaQueries/media";

export const Container = styled.section`
  background: ${(props) => props.primaryColor};
  display: flex;
  
  @media(max-width: ${breakpoints.lg}) {
    display: none;
  }
`

export const MainContainer = styled.div`
      align-items:center;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      width: 1280px;
      padding-bottom: 20px;
      justify-items: center;
`

export const CTAContainer = styled.div`
    width: 100%;
`


export const MainTags = styled.div`
  background-color: ${(props) => props.bgColor};
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.white} !important;
  height: 31px;
  margin-top: 30px;
  padding: 6px;
  width: fit-content;
`

export const MainIcon = styled.a`
  align-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.primary.base};
  display: flex;
  justify-content: center;
  height: 86px;
  margin: 3% 3%;
  width: 100%;
  text-decoration: none;
  & img {
    width: fit-content;
  }
  
  & p {
    align-self: center;
    
  }
  
  @media(min-width: 1366px) {
    margin: 3% 1%;
  }
`

export const MainTitle = styled.div`
   color: ${({ theme }) => theme.colors.white};
   font-size: 35px;
   font-weight: 700;
   margin: 8px 0;
`

export const MainSubtitle = styled.div`
    color: ${({ theme }) => theme.colors.white};
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 32px;
    width: 540px;
`

export const MainImage = styled.div`
    margin-top: 90px;
    width: 90%;
    position: absolute;
    
    & img {
        border-radius: 8px;
        width: fit-content;
        height: 500px;
    }
`
