import React from "react";
import PropTypes from "prop-types";
import { Image, Typography } from "components/common";
import WhiteLogo from "assets/images/white-logo.svg";
import PadLock from "assets/icons/padLock.svg";
import * as S from "./styled";

const Footer = ({ step }) => {
  return (
    <S.Container step={step}>
      <Image src={WhiteLogo} alt="logo" />

      <S.Info>
        <Image src={PadLock} alt="padlock" />
        <Typography tag="h4" spacing="xs">
          Ambiente seguro ·
        </Typography>
        <Typography tag="h4" spacing="xs">
          ConectCar CNPJ: 16.577.631/0002-99 |
        </Typography>
        <Typography tag="h4">© Todos os direitos reservados </Typography>
      </S.Info>
    </S.Container>
  );
};

Footer.propTypes = {
  step: PropTypes.string,
};

Footer.defaultProps = {
  step: "",
};

export default Footer;
