import { format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'

const transformDate = (date = new Date(), fmt = 'dd/MM/yyyy') =>
  format(date, fmt, { locale: ptBR })

/**
 * formatDate
 *
 * Format date using lib date-fns
 *
 * @param {(Date|string)} date - Object Date or string date
 * @param {string} [fmt] - Optional string to define format date
 *
 * @returns {string} - Date string formatted
 *
 * @example
 * formatDate(new Date(), 'dd/MM/yyyy')
 * formatDate('2000-01-01', 'dd/MM/yyyy')
 *
 * @see {@link https://date-fns.org/v2.15.0/docs/format} - More information on documentation date-fns
 */
export const formatDate = (date, fmt) => {
  if (typeof date === 'string') {
    const dateString = date.replace(/-/g, '/')

    return transformDate(new Date(dateString), fmt)
  }

  return transformDate(date, fmt)
}

export default formatDate
