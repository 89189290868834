import styled from 'styled-components'
import breakpoints from 'utils/mediaQueries/media'

export const Form = styled.form`
  width: 100%;

  @media (max-width: ${breakpoints.md}) {
    padding: 0 ${({ theme }) => theme.spacing.mdl};
  }

  @media (max-width: ${breakpoints.sm}) {
    padding: 0;
  }
`
