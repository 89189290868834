import * as Yup from "yup";
import ValidationRules from "../validations/validationsSchemas";

export const companyDataSchema = Yup.object().shape({
  cnpj: ValidationRules.Cnpj(),
  socialName: ValidationRules.Required(),
  dateOfIncorporation: ValidationRules.DateNotFuture(),
  legalNature: ValidationRules.Required(),
  name: ValidationRules.Required(),
  lastName: ValidationRules.Required(),
  cpf: ValidationRules.Cpf(),
  representativeSince: ValidationRules.DateNotFuture(),
});
