import React from 'react';
import {
  Grid,
  Typography,
} from 'components/common';



const ListPage = () => {

  return (
    <Grid>
      <Typography tag="h1" variant="secondary">
        Restante dos componentes
      </Typography>
    </Grid>
  )
}

ListPage.defaultProps = {
  className: null,
  containerSize: "100"
}

export {ListPage}
