/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { map, without } from "lodash";
import Select, { components } from "react-select";
import * as S from "./styled";
import ArrowDownIcon from "assets/icons/arrow-down.svg";
import { Image } from "components/common";
import IconClose from "assets/icons/close.svg";

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <Image src={ArrowDownIcon} alt="ArrowDownIcon" />
      </components.DropdownIndicator>
    )
  );
};

export const Autocomplete = ({
  label,
  placeholder,
  id,
  errorMessage,
  onChange,
  onBlur,
  value,
  options,
  onClear,
  isCleaner,
  spacing,
}) => {
  const customStyles = {
    control: (props) => ({
      ...props,
      border: `1px solid ${errorMessage ? "#e44126" : "#BEC4CC"} ;`,
      boxShadow: "none",
      "&:hover": {
        border: `1px solid ${errorMessage ? "#e44126" : "#BEC4CC"}`,
      },

      backgroundColor: "#FBFAFA",
      minHeight: 55,
      borderRadius: 4,
      padding: "0 20px",
    }),
    valueContainer: (props) => ({
      ...props,
      padding: 0,
      fontStyle: "normal",
      fontSize: "15px",
      lineHeight: "17px",
      color: "#4D5761",
    }),
  };
  return (
    <S.Container onClick={onBlur} spacing={spacing}>
      <S.Label>{label}</S.Label>
      <Select
        id={id}
        placeholder={placeholder}
        options={options}
        styles={customStyles}
        onChange={(obj) => onChange(obj.value)}
        onBlur={onBlur}
        value={without(
          map(options, (item) => item.value === value && item),
          undefined
        )}
        noOptionsMessage={() => "Nenhum resultado encontrado"}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator,
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "#FF6338",
          },
        })}
      />
      <S.ErrorMessage>{errorMessage && errorMessage}</S.ErrorMessage>
      {isCleaner && value && (
        <S.ShowIconClose onClick={onClear}>
          <Image src={IconClose} alt="IconClose" />
        </S.ShowIconClose>
      )}
    </S.Container>
  );
};

Autocomplete.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onClear: PropTypes.func,
  label: PropTypes.string,
  isCleaner: PropTypes.bool,
  errorMessage: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  spacing: PropTypes.oneOf([
    "",
    "min",
    "xs",
    "smx",
    "sm",
    "sml",
    "base",
    "mdx",
    "md",
    "mdl",
    "lgx",
    "lg",
    "lgl",
    "max",
  ]),
};

Autocomplete.defaultProps = {
  isCleaner: false,
  label: "",
  errorMessage: "",
  onClear: () => {},
  onBlur: () => {},
  placeholder: "Selecione",
  options: [],
  spacing: "",
};
