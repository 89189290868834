import styled, { css } from "styled-components";

const wrapperModifiers = {
  h1: (theme, variant) => css`
    color: ${variant === "primary" ? "#2A3037" : theme.colors.secondary.base};
    font-size: ${theme.fontSize.xl};
  `,
  h2: (theme, variant) => css`
    color: ${variant === "primary"
      ? theme.colors.primary.base
      : theme.colors.secondary.base};
    font-size: ${theme.fontSize.lg};
  `,
  h3: (theme, variant) => css`
    color: ${variant === "primary"
      ? theme.colors.gray
      : theme.colors.secondary.base};
    font-size: ${theme.fontSize.lg};
  `,
  h4: (theme, variant) => css`
    color: ${variant === "primary" ? "#EDEDED" : "#2A3037"};
    font-size: ${theme.fontSize.base};
    font-weight: 700;
  `,
  h5: (theme, variant) => css`
    color: ${variant === "primary" ? theme.colors.secondary.base : "#2A3037"};
    font-size: ${theme.fontSize.md};
    font-weight: 700;
  `,

  h6: (theme, variant) => css`
    color: ${variant === "primary" ? "#676767" : theme.colors.primary.base};
    font-size: ${theme.fontSize.xlg};
    font-weight: 700;
  `,
  text: (theme, variant) => css`
    color: ${variant === "primary" ? theme.colors.gray : "#6A6A6A"};
    font-size: ${theme.fontSize.base};
    font-weight: ${theme.fontWeight.base};
  `,
  support: (theme, variant) => css`
    color: ${variant === "primary" ? "#707070" : theme.colors.secondary.dark};
    font-size: ${theme.fontSize.default};
  `,
  disclaimer: (theme, variant) => css`
    color: ${variant === "primary" ? theme.colors.gray : theme.colors.primary};
    font-size: ${theme.fontSize.sm};
    font-weight: ${theme.fontWeight.disclaimer};
  `,
  label: (theme) => css`
    color: ${theme.colors.gray};
    font-size: ${theme.fontSize.md};
    text-transform: uppercase;
  `,
  subtitle: (theme, variant) => css`
    color: ${variant === "primary"
      ? theme.colors.secondary.dark
      : theme.colors.secondary.light};
    font-size: ${theme.fontSize.xmd};
    font-weight: ${theme.fontWeight.base};
  `,
  default: (theme, variant) => css`
    color: ${variant === "primary" ? theme.colors.black : "#4D5761"};
    font-size: ${theme.fontSize.base};
  `,
  heading: () => css``,
  normal: () => css``,
};

const tags = ["text", "support", "disclaimer", "default", "subtitle"];

export const Wrapper = styled("p").attrs(({ tag }) => ({
  as: `${tags.includes(tag) ? "p" : tag}`,
}))`
  ${({ theme, tag, variant, spacing, align }) => css`
    margin-bottom: ${spacing === "" ? 0 : `${theme.spacing[spacing]}`};
    text-align: ${align};

    ${!tags.includes(tag) && wrapperModifiers.heading(theme)}
    ${tags.includes(tag) && wrapperModifiers.normal(theme)}
    ${!!tag && wrapperModifiers[tag](theme, variant)}
  `}
`;
