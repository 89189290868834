/* eslint-disable import/no-anonymous-default-export */
import { combineReducers } from "redux";

import dialogs from "./Dialogs";
import wrapper from "./Wrapper";
import address from "./Address";
import registration from "./Registration";
import landingPage from "./LandingPage";
import payment from "./Payment";
import order from "./Order";

export default () =>
  combineReducers({
    wrapper,
    dialogs,
    address,
    registration,
    landingPage,
    payment,
    order
  });
