import styled from "styled-components";
import breakpoints from "utils/mediaQueries/media";

export const Container = styled.header`
  background-color: #f7f2ed;
  height: 119px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 18px;

  @media (min-width: ${breakpoints.sm}) {
    background-color: #ffffff;
    height: 88px;
  }

  @media (min-width: ${breakpoints.md}) {
    padding: 0 20px;
  }

  @media (min-width: ${breakpoints.lg}) {
    padding: 0 320px;
  }
`;

export const HeaderInfo = styled.div`
  display: none;

  @media (min-width: ${breakpoints.sm}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
`;

export const TextBox = styled.div`
  display: flex;
  align-items: center;
`;

export const Separator = styled.div`
  width: 1px;
  height: 51px;
  background: #ededed;
  margin: 0 28px;
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 70px;

  @media (min-width: ${breakpoints.sm}) {
    display: none;
  }
`;

export const StepNumber = styled.span`
  font-family: Lato;
  font-weight: bold;
  font-size: 13px;
  color: ${({ isLast }) => (isLast ? "#293b8a" : "#4D5761")};
`;

export const BtnBackToHome = styled.div`
  font-family: Lato;
  font-weight: bold;
  font-size: 18px;
  color: #f24819;

  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  align-items: center;
  cursor: pointer;
  display: flex;
  user-select: none;
`;
