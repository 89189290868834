import {
  FETCH_GET_ADDRESS_BY_POSTAL_CODE_FAILURE,
  FETCH_GET_ADDRESS_BY_POSTAL_CODE_REQUEST,
  FETCH_GET_ADDRESS_BY_POSTAL_CODE_SUCCESS,
  UPDATE_ADDRESS_BY_POSTAL_CODE,
  SET_ADRESS_DATA
} from "redux/constants/Address/Data";

import { getAddressByZipCode } from "config/api/Address";

const fetchGetAddressByPostalCodeRequestRequest = () => ({
  type: FETCH_GET_ADDRESS_BY_POSTAL_CODE_REQUEST,
});

const fetchGetAddressByPostalCodeSuccess = (payload) => ({
  type: FETCH_GET_ADDRESS_BY_POSTAL_CODE_SUCCESS,
  payload,
});

const fetchGetAddressByPostalCodeFailure = (payload) => ({
  type: FETCH_GET_ADDRESS_BY_POSTAL_CODE_FAILURE,
  payload,
});

const setAdressData = (payload) => ({
  type: SET_ADRESS_DATA,
  payload,
});

const fetch = (request, dispatch) => {
  dispatch(fetchGetAddressByPostalCodeRequestRequest());
  getAddressByZipCode(request)
    .then((response) => {
      dispatch(fetchGetAddressByPostalCodeSuccess(response.data));
    })
    .catch(() => {
      dispatch(fetchGetAddressByPostalCodeFailure());
    });
};

export const actionGetAddress = (params) => (dispatch) =>
  fetch(params, dispatch);

export const actionUpdateAddress = (payload) => ({
  type: UPDATE_ADDRESS_BY_POSTAL_CODE,
  payload,
});

export const actionSetAdress = (request) => (dispatch) =>
  dispatch(setAdressData(request))
