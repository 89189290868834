import { SHOW_SNACKBAR } from 'redux/constants/Dialogs/Snackbar'

const initialState = {
  payload: {
    show: false,
    type: '',
    alwaysVisible: false,
    secondTimeOutToClose: 5,
    message: ''
  }
}

const Snackbar = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_SNACKBAR:
      return {
        ...state,
        payload: action.payload
      }
    default:
      return state
  }
}

export default Snackbar
