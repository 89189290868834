import { Row } from 'react-grid-system'

import styled from 'styled-components'

export const RowWrapper = styled(Row)`
  margin-bottom: ${({ spacing, theme }) =>
    spacing === '' ? 0 : `${theme.spacing[spacing]}`};
  margin-left: 0;
  margin-right: 0;
`
